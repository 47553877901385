import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AccountPlanDTOResponse } from 'src/model/dto/AccountPlanDTOResponse';
import { AccountProvider } from 'src/providers/account/account';
import { PostingProvider } from 'src/providers/bookkeeping/posting/posting';
import { Snackbar } from 'src/util/snackbar';
import Swal from 'sweetalert2';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ImovelDTO } from 'src/model/dto/ImovelDTO';
import { GrouperDTOResponse } from 'src/model/dto/GrouperDTOResponse';
import { ParticipantProvider } from 'src/providers/participant/participant';
import { ReceiptProvider } from 'src/providers/receipt/receipt';
import { HistoricProvider } from 'src/providers/bookkeeping/historic/historic';
import { GrouperProvider } from 'src/providers/grouper/grouper';
import { BankAccountProvider } from 'src/providers/company/bank_account/bank_account';
import { PropertyProvider } from 'src/providers/company/property/property';
import { historicoUtil } from 'src/util/historico';
import { ConvertObjectPipe } from 'src/util/pipes/ConvertObjectPipe';
import * as moment from 'moment';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatStepper } from '@angular/material';
import { CadastroAgrupadoresComponent } from '../../cadastros/agrupadores/cadastro-agrupadores/cadastro-agrupadores.component';
import { CadastroContaBancariaComponent } from '../../minhasPropriedades/cadastro-conta-bancaria/cadastro-conta-bancaria.component';
import { CadastroImovelComponent } from '../../minhasPropriedades/cadastro-imovel/cadastro-imovel.component';
import { FilterSettingsModel, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { AdicionarArquivosComponent } from '../../consultor/enviar-arquivos/adicionar-arquivos/adicionar-arquivos.component';
import { HistoricDTOResponse } from 'src/model/dto/HistoricDTOResponse';

@Component({
  selector: 'app-integracao-dominio',
  templateUrl: './integracao-dominio.component.html',
  styleUrls: ['./integracao-dominio.component.scss']
})
export class IntegracaoDominioComponent implements OnInit {

  protected _onDestroy = new Subject<void>();
  public loading: boolean = true;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  // Parte 1 (primeiros 2 posts)
  public importedFile: File = null;
  public importType: string = "DOMINIO";
  public importModel: Number = null;
  public farmcontAccounts = null;
  public foreignAccounts: any = null;
  public participantAccountsFormArray = new FormArray([]);
  public filter_planAccount: ReplaySubject<AccountPlanDTOResponse[]>[] = [];
  public fileUploaded: boolean = false;

  // Parte 2 (criação dos lançamentos)
  public submittedForm: boolean = false;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public changeMade = false;
  public participants = null;
  public accounts = null;
  public importedLancamentos = null;
  public lancamentoIntegracaoForm = new FormGroup({
    documento: new FormControl(''),
    imovel: new FormControl('', Validators.required),
    data: new FormControl(''),
    dataFgts: new FormControl(''),
    dataInss: new FormControl(''),
    contaMovimento: new FormControl('', Validators.required),
    historic: new FormControl(''),
    modelo: new FormControl(''),
    provider: new FormControl(new historicoUtil)
  });
  //PROPERTIES
  public imoveis: any[];
  public propertyFilterCtrl: FormControl = new FormControl();
  public filter_property: ReplaySubject<ImovelDTO[]> = new ReplaySubject<ImovelDTO[]>(1);
  //BANK ACCOUNTS
  public contas: any[];
  public bankAccountFilterCtrl: FormControl = new FormControl();
  public filter_bankAccount: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  //HISTORIC
  public historicos: any[];
  @ViewChild('historicInput', { static: false }) historicInput: ElementRef<HTMLInputElement>;
  public historicFilterCtrl: FormControl = new FormControl();
  public filter_historic: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  // GROUPERS
  @ViewChild('grouperInput', { static: false }) grouperInput: ElementRef<HTMLInputElement>;
  public allGroupers: any[];
  public filteredGroupers: GrouperDTOResponse[];
  public filteredGrouper: Observable<GrouperDTOResponse[]>;
  public groupers: any = [];
  public grouperCtrl = new FormControl();
  //RECEIPTS
  @ViewChild('receiptInput', { static: false }) receiptInput: ElementRef<HTMLInputElement>;
  public allReceipts: any[];
  public filteredReceipts: any[];
  public filteredReceipt: Observable<any[]>;
  public receipts: any = [];
  public receiptCtrl = new FormControl();

  public requiredDateSalario: boolean = false;
  public requiredDateFgts: boolean = false;
  public requiredDateInss: boolean = false;

  // Parte 3 (prévia + ultimos 2 posts)
  public dataSource: any[] = [];
  public competenceMonth: any = null;
  @ViewChild('grid', { static: false }) previewGrid;
  public filterSettings: FilterSettingsModel;
  public selectionSettings: SelectionSettingsModel;
  public customAttributes: any;
  public keyword: string = null;
  public filter: any;

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == 'Escape') {
      this.activeModal.close(false);
    }
  }

  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public snackBar: Snackbar,
    public postingProvider: PostingProvider,
    public accountProvider: AccountProvider,
    public fb: FormBuilder,
    public propertyProvider: PropertyProvider,
    public bankAccountProvider: BankAccountProvider,
    public historicProvider: HistoricProvider,
    public grouperProvider: GrouperProvider,
    public receiptProvider: ReceiptProvider,
    public participantProvider: ParticipantProvider,
    public adicionarArquivosModalService: NgbModal

  ) { }

  ngOnInit() {
    this.initializeData();
  }

  initializeData() {
    //get das contas movimento farmcont (p popular os selects p cada conta estrangeira)
    this.accountProvider.getAllExpenseAccounts().then((accounts) => {
      this.farmcontAccounts = accounts;
      this.createForm();
      this.accountsToForm();
    }).catch((error) => {
      console.log("error", error);
      this.loading = false;
      this.snackBar.openLong('Ocorreu um erro ao carregar as contas. ' + error.error.error_description, 'erro');
    });
  }

  initializeData2() {
    this.loading = true;
    let pipe = new ConvertObjectPipe();
    this.propertyProvider.getAllProperty().then((imoveis: any[]) => {
      this.imoveis = pipe.transform(imoveis, 'imoveis');

      this.bankAccountProvider.getAllBanksAccounts().then((contas: any[]) => {
        this.contas = pipe.transform(contas, 'contasBancarias');

        this.grouperProvider.getAllGroupers().then((groupers: GrouperDTOResponse[]) => {
          this.allGroupers = groupers;
          this.filteredGroupers = this.allGroupers;

          this.receiptProvider.getReceiptsByCompanyId().then((receipts: any[]) => {
            this.allReceipts = receipts;
            this.allReceipts.forEach((receipt) => {
              receipt.formatedDate = moment(receipt.date).locale('pt-BR').format('DD/MM/YYYY')
            })
            this.filteredReceipts = this.allReceipts;

            this.participantProvider.getAllParticipants().then((participants: any[]) => {
              this.participants = participants;

              this.accountProvider.getAllExpenseAccounts().then((accounts: any[]) => {
                this.accounts = accounts;

                this.historicProvider.getAllHistorics().then((historics: any[]) => {
                  this.historicos = this.sortHistorics(pipe.transform(historics, 'contasBancarias'));

                  this.initializeSearchSelect();
                  this.initializeDefaultValues();
                  this.loading = false;
                }).catch((error) => {
                  this.loading = false;
                  this.snackBar.open('Erro ao carregar históricos. ' + error.error.error_description, 'erro');
                })
              }).catch((error) => {
                this.loading = false;
                this.snackBar.open('Erro ao carregar contas. ' + error.error.error_description, 'erro');
              });
            }).catch((error) => {
              this.loading = false;
              this.snackBar.open('Erro ao carregar participantes. ' + error.error.error_description, 'erro');
            });
          }).catch((error) => {
            this.loading = false;
            this.snackBar.open('Erro ao carregar arquivos. ' + error.error.error_description, 'erro');
          });
        }).catch((error) => {
          this.loading = false;
          this.snackBar.open('Erro ao carregar agrupadores. ' + error.error.error_description, 'erro');
        })
      }).catch((error) => {
        this.loading = false;
        this.snackBar.open('Erro ao carregar contas bancárias. ' + error.error.error_description, 'erro');
      });
    }).catch((error) => {
      this.loading = false;
      this.snackBar.open('Erro ao carregar imóveis. ' + error.error.error_description, 'erro');
    });
  }

  initializeSearchSelect() {
    //console.log("initialize search select")
    this.filter_property.next(this.imoveis.slice());
    this.propertyFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProperties();
      });

    this.filter_bankAccount.next(this.contas.slice());
    this.bankAccountFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBankAccounts();
      });

    this.filteredGrouper = this.grouperCtrl.valueChanges.pipe(
      startWith(''),
      map((fruit: string | null) => fruit ? this._filterG(fruit) : this.allGroupers.slice()));

    this.filteredReceipt = this.receiptCtrl.valueChanges.pipe(
      startWith(''),
      map((fruit: string | null) => fruit ? this._filterR(fruit) : this.allReceipts.slice()));
  }

  initializeDefaultValues() {
    this.lancamentoIntegracaoForm.get('imovel').setValue(this.imoveis.find(obj => obj.default));
    this.lancamentoIntegracaoForm.get('contaMovimento').setValue(this.contas.find(obj => obj.default));
  }

  createForm() {
    let foreignAccounts = ['SALARIO', 'FGTS', 'INSS'];
    foreignAccounts.forEach((account) => {
      const formGroup = this.fb.group({
        id: new FormControl(null),
        accountId: new FormControl(null),
        foreignDescription: new FormControl(account),
        skipPosting: new FormControl(false),
        accountComponent: new FormControl(null),
        importType: new FormControl(this.importType),
        planAccount_filter_Ctrl: []
      })
      this.participantAccountsFormArray.push(formGroup);
    });

    foreignAccounts.forEach((account, i) => {
      this.filter_planAccount[i] = new ReplaySubject<any[]>(1);
      this.participantAccountsFormArray.controls[i].get('planAccount_filter_Ctrl').valueChanges
        .pipe(
          takeUntil(this._onDestroy),
          startWith(this.participantAccountsFormArray.controls[i].get('planAccount_filter_Ctrl').value))
        .subscribe(() => {
          this.filterPlanAccounts(i);
        });
    })
  }

  accountsToForm() {
    this.accountProvider.getDominioBindedAccounts().then((result: []) => {
      result.forEach((account: any) => {
        this.participantAccountsFormArray.controls.forEach((formGroup: FormGroup) => {
          if (formGroup.get('foreignDescription').value == account.foreignDescription) {
            formGroup.get('id').setValue(account.id);
            formGroup.get('accountId').setValue(account.accountId);
            formGroup.get('skipPosting').setValue(account.skipPosting);
            formGroup.get('accountComponent').setValue(account.accountId ? this.farmcontAccounts.find((account2) => { return account2.id == account.accountId }) : null);
          }
        })
      })
      this.loading = false;
    }).catch((error) => {
      console.log("error", error);
      this.loading = false;
      this.snackBar.openLong('Ocorreu um erro ao carregar os vínculos. ' + error.error.error_description, 'erro');
    })
  }

  filterPlanAccounts(i) {
    if (!this.farmcontAccounts) {
      return;
    }
    let search = this.participantAccountsFormArray.controls[i].get('planAccount_filter_Ctrl').value;
    if (!search) {
      this.filter_planAccount[i].next(this.farmcontAccounts.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filter_planAccount[i].next(
      this.farmcontAccounts.filter(value => value.description.toLowerCase().indexOf(search) > -1)
    );
  }

  openFileModal() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
      const modalRef = this.adicionarArquivosModalService.open(AdicionarArquivosComponent, modalOptions);
      modalRef.result.then((result: any) => {
        if (result !== 'Cancelou') {
          this.receiptProvider.getReceiptsByCompanyId().then((receipts: any[]) => {
            this.allReceipts = receipts;
            this.allReceipts.forEach((receipt) => {
              receipt.formatedDate = moment(receipt.date).locale('pt-BR').format('DD/MM/YYYY')
            })
            this.filteredReceipts = this.allReceipts;
            this.receiptCtrl.setValue('');
            if (result.lenght != 0) {
              result.forEach((receipt) => { this.receipts.push(receipt) })
            }

          });
        }
      });
    } catch (error) {
      console.error('Erro ao abrir modal de adicionar arquivos!', error);
    }
  }

  insertFile() {
    try {
      document.getElementById('fileInsert').click();
    } catch (exception) {
      console.error('Erro ao inserir arquivo!', exception);
    }
  }

  fileChange() {
    try {
      const fileInput = (<HTMLInputElement>document.getElementById('fileInsert'));
      this.importedFile = fileInput.files[0];
      this.stepper.selected.completed = true;
    } catch (exception) {
      console.error('Erro ao inserir arquivo!', exception);
    }
  }

  importedFileRemove() {
    this.importedFile = null;
    this.stepper.selected.completed = false;
  }

  submitStep1() {
    this.loading = true;
    let accountsToPost = [];
    this.participantAccountsFormArray.controls.forEach((formGroup: FormGroup) => {
      if (formGroup.get('id').value != null || formGroup.get('accountComponent').value != null) {
        formGroup.get('accountId').setValue(formGroup.get('accountComponent').value ? formGroup.get('accountComponent').value.id : null);
        accountsToPost.push(formGroup.getRawValue());
      }
    })
    this.accountProvider.postBindedForeignAccounts(accountsToPost).then((result) => {
      let model = this.importModel == 0 ? 'RESUMO' : 'EXTRATO';
      this.postingProvider.postImportedFile(this.importedFile, model).then((result : any[]) => {


        if(result.find((account) => { return account.descriptionForeignType == 'SALARIO' }) != undefined){
          //console.log("a");
          this.lancamentoIntegracaoForm.get('data').setValidators([Validators.required]);
          this.requiredDateSalario = true;
        }
        if(result.find((account) => { return account.descriptionForeignType == 'INSS' }) != undefined){
          //console.log("a1");
          this.lancamentoIntegracaoForm.get('dataInss').setValidators([Validators.required]);
          this.requiredDateInss = true;
        }
        if(result.find((account) => { return account.descriptionForeignType == 'FGTS' }) != undefined){
          //console.log("a2");
          this.lancamentoIntegracaoForm.get('dataFgts').setValidators([Validators.required]);
          this.requiredDateFgts = true;
        }

        this.loading = false;
        this.fileUploaded = true;
        this.snackBar.openLong('Contas mapeadas com sucesso!', 'success');
        this.importedLancamentos = result;
        this.competenceMonth = this.importedLancamentos[0].competenceMonth;
        this.stepper.selected.completed = true;
        this.stepper.next();
        this.initializeData2();
      }).catch((error) => {
        console.log("error", error);
        this.loading = false;
        this.snackBar.openLong('Ocorreu um erro ao enviar o arquivo. ' + error.error.error_description, 'erro');
      })
    }).catch((error) => {
      console.log("error", error);
      this.loading = false;
      this.snackBar.openLong('Ocorreu um erro ao mapear as contas. ' + error.error.error_description, 'erro');
    })
  }

  submitStep2() {
    if (!this.lancamentoIntegracaoForm.valid) {
      this.submittedForm = true;
      this.snackBar.open('Preencha todos os campos obrigatórios!', 'erro');
      return;
    }
    this.loading = true;
    this.dataSource = this.mapLancamentos();
      //console.log("submitStep2 ~ this.dataSource:", this.dataSource)
    this.tableConfiguration();
    this.stepper.selected.completed = true;
    this.stepper.next();
    this.loading = false;
  }

  submitImportedFile() {
    return new Promise((resolve, reject) => {
      this.postingProvider.postImportedFileToPosting(this.importedFile, (this.importModel == 0 ? 'RESUMO_DOMINIO' : 'EXTRATO_DOMINIO')).then((result: any) => {
        this.dataSource.forEach((data) => {
          data.doc.receiptFileIds.push(result);
        })
        resolve(true);
      }).catch((error) => {
        this.loading = false;
        this.snackBar.open('Erro ao enviar arquivo. ' + error.error.error_description, 'erro');
        reject(false);
      })
    })
  }

  submitImportedPostings() {
    Swal.fire({
      title: 'Tem certeza que deseja escriturar estes lançamentos?',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, escriturar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.submitImportedFile().then((result) => {
          if (result) {
            this.postingProvider.importPayroll(this.dataSource, (this.importModel == 0 ? 'RESUMO' : 'EXTRATO')).then((result) => {
              this.loading = false;
              this.snackBar.open('Lançamentos escriturados com sucesso!', 'success');
              this.activeModal.close(true);
            }).catch((error) => {
              this.loading = false;
              this.snackBar.open('Erro ao escriturar lançamentos. ' + error.error.error_description, 'erro');
            })
          }
        }).catch((error) => {
          this.loading = false;
          this.snackBar.open('Erro ao enviar arquivo. ' + error.error.error_description, 'erro');
        });
      }
    });
  }

  tableConfiguration() {
    this.customAttributes = { class: 'customcss' };
    this.filterSettings = { type: 'Menu' };
    this.filter = { type: 'CheckBox' };
    this.selectionSettings = { persistSelection: false, type: 'Multiple', checkboxOnly: true };
  }

  mapLancamentos() {

    let lancamentos = [];

    //console.log("this.importedlancamentos" , this.importedLancamentos)

    this.importedLancamentos.forEach((importedLancamento) => {
      let posting = new ImportedPosting();

      if(importedLancamento.descriptionForeignType == 'INSS'){
        posting.paymentDate = moment(this.lancamentoIntegracaoForm.value.dataInss).format('YYYY-MM-DD');
      } else if(importedLancamento.descriptionForeignType == 'FGTS'){
        posting.paymentDate = moment(this.lancamentoIntegracaoForm.value.dataFgts).format('YYYY-MM-DD');
      } else{
        posting.paymentDate = moment(this.lancamentoIntegracaoForm.value.data).format('YYYY-MM-DD');
      }

      posting.amountPaid = (importedLancamento.amountPaid).toString();
      posting.bankAccountId = this.lancamentoIntegracaoForm.value.contaMovimento.id;
      posting.bankAccount = this.lancamentoIntegracaoForm.value.contaMovimento;
      posting.accountPlanId = importedLancamento.accountId;
      posting.accountPlan = this.accounts.find((account) => account.id == importedLancamento.accountId);
      posting.postingReleaseType = "INVESTMENT_COST_EXPENSES";
      posting.importType = this.importType;
      posting.gouperIds = this.groupers.map((grouper) => grouper.id);

      let doc = new ImportedDoc();
      doc.number = this.lancamentoIntegracaoForm.value.documento;
      doc.docType = "PAYROLL";
      doc.propertyCode = this.lancamentoIntegracaoForm.value.imovel.propertyCode;
      doc.property = this.lancamentoIntegracaoForm.value.imovel;
      doc.participantId = importedLancamento.participantId;
      doc.participant = this.participants.find((participant) => participant.id == importedLancamento.participantId);
      doc.receiptFileIds = this.receipts.map((receipt) => receipt.id);

      posting.historic = this.generateHistoric(this.lancamentoIntegracaoForm.value.modelo, doc, posting)
      posting.historicFormated = JSON.parse(posting.historic).historico
      doc.posting = posting;
      lancamentos.push({ doc: doc });
    })

    return lancamentos;
  }

  //--------------------------------PROPERTIES--------------------------------\\
  protected filterProperties() {
    if (!this.imoveis) {
      return;
    }
    let search = this.propertyFilterCtrl.value;
    if (!search) {
      this.filter_property.next(this.imoveis.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filter_property.next(
      this.imoveis.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }
  newProperty() {
    let pipe = new ConvertObjectPipe();
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroImovelComponent, modalOptions);
    modalRef.result.then((result) => {
      this.loading = true;
      this.propertyProvider.getAllProperty().then((imoveis: any[]) => {
        this.loading = false;
        this.imoveis = pipe.transform(imoveis, 'imoveis');
        this.initializeSearchSelect();
      }).catch((error) => {
        this.loading = false;
        this.snackBar.open('Erro ao carregar imóveis. ' + error.error.error_description, 'erro');
      });
    }, () => {
    });
  }
  editProperty(row) {
    this.loading = true;
    this.propertyProvider.getAllProperty().then((imoveis: any[]) => {
      let property = imoveis.find((imovel) => imovel.propertyCode == row.propertyCode);
      this.loading = false;
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
      };
      const modalRef = this.modalService.open(CadastroImovelComponent, modalOptions);
      modalRef.componentInstance.imovelExistente = property;
      modalRef.result.then((result) => {
        if (result) {
          this.loading = true;
          this.propertyProvider.getAllProperty().then((imoveis: any[]) => {
            let pipe = new ConvertObjectPipe();
            this.imoveis = pipe.transform(imoveis, 'imoveis');
            this.initializeSearchSelect();
            this.loading = false;
          }).catch((error) => {
            this.loading = false;
            this.snackBar.open('Erro ao carregar imóveis. ' + error.error.error_description, 'erro');
          });
        }
      }, () => {
      });
    }).catch((error) => {
      this.loading = false;
      this.snackBar.open('Erro ao carregar imóveis. ' + error.error.error_description, 'erro');
    });
  }

  //--------------------------------BANK ACCOUNTS--------------------------------\\
  protected filterBankAccounts() {
    if (!this.contas) {
      return;
    }
    let search = this.bankAccountFilterCtrl.value;
    if (!search) {
      this.filter_bankAccount.next(this.contas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filter_bankAccount.next(
      this.contas.filter(bank => bank.description.toLowerCase().indexOf(search) > -1)
    );
  }
  newBankAccount() {
    let pipe = new ConvertObjectPipe();
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroContaBancariaComponent, modalOptions);
    modalRef.result.then((result) => {
      this.loading = true;
      this.bankAccountProvider.getAllBanksAccounts().then((contas: any[]) => {
        this.loading = false;
        this.contas = pipe.transform(contas, 'contasBancarias');
        this.initializeSearchSelect();
      }).catch((error) => {
        this.loading = false;
        this.snackBar.open('Erro ao carregar contas bancárias. ' + error.error.error_description, 'erro');
      });
    }, () => {
    });
  }
  editBankAccount(row) {
    this.loading = true;
    this.bankAccountProvider.getAllBanksAccounts().then((contas: any[]) => {
      let bankAccount = contas.find((conta) => conta.id == row.id);
      this.loading = false;
      let modalOptions: NgbModalOptions = {};
      modalOptions.backdrop = 'static';
      const modalRef = this.modalService.open(CadastroContaBancariaComponent, modalOptions);
      modalRef.componentInstance.contaExistente = bankAccount;
      modalRef.result.then((result) => {
        if (result) {
          this.loading = true;
          this.bankAccountProvider.getAllBanksAccounts().then((contas: any[]) => {
            let pipe = new ConvertObjectPipe();
            this.contas = pipe.transform(contas, 'contasBancarias');
            this.initializeSearchSelect();
            this.loading = false;
          }).catch((error) => {
            this.loading = false;
            this.snackBar.open('Erro ao carregar contas bancárias. ' + error.error.error_description, 'erro');
          });
        }
      }, () => {
      });
    }).catch((error) => {
      this.loading = false;
      this.snackBar.open('Erro ao carregar contas bancárias. ' + error.error.error_description, 'erro');
    });
  }

  //--------------------------------HISTORIC--------------------------------\\
  sortHistorics(historicos) {
    return historicos.sort((a, b) => {
      if (a.code > b.code) {
        return 1;
      } else if (a.code < b.code) {
        return -1;
      } else {
        return 0;
      }
    })
  }

  filterHistoric() {
    let search = this.lancamentoIntegracaoForm.get('historic').value;
    if (!search) {
      this.filter_historic.next(this.generateHistoricModels().slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filter_historic.next(
      this.generateHistoricModels().filter(value => (value.description.toLowerCase().indexOf(search) > -1 ||
        value.code.toLowerCase().indexOf(search) > -1))
    );
  }

  generateHistoric(model, doc, posting) {
    let historicSplit = model.split(' ');
    let historicString = '';
    historicSplit.forEach(str => {
      if (str.includes('@')) {
        historicString = historicString + ' ' + this.getHistoricTag(str.trim(), doc, posting);
      } else {
        historicString = historicString + ' ' + str.trim();
      }
    });
    let historicObj = {historico: historicString, modelo: model};
    return JSON.stringify(historicObj);
  }

  generateHistoricModels(): HistoricDTOResponse[] {
    const modelStorage = this.historicos;
    const modelsFormated = [];
    modelStorage.forEach((historic: HistoricDTOResponse) => {
      const historicSplit = historic.model.split('#');
      let historicString = '';
      historicSplit.forEach(str => {
        historicString = historicString + ' ' + str.trim();
      });
      historic.model = historicString.trim();
      modelsFormated.push(historic);
    });
    return modelsFormated;
  }

  getHistoricTag(tag, doc, posting) {
    switch (tag) {
      case '@participante@':
        return this.participants.find((participant) => participant.id == doc.participantId).name.toUpperCase();
      case '@participante-CpfCnpj@':
        return this.participants.find((participant) => participant.id == doc.participantId).cpfCNPJParticipant;
      case '@numero-documento@':
        return doc.number;
      case '@conta@':
        return this.accounts.find((account) => account.id == posting.accountPlanId).description;
      case '@mes@':
        return moment(this.lancamentoIntegracaoForm.get('data').value).locale('pt-br').format('MMM').toUpperCase();
      case '@ano@':
        return moment(this.lancamentoIntegracaoForm.get('data').value).format('Y');
      case '@ano-anterior@':
        return moment(this.lancamentoIntegracaoForm.get('data').value).subtract(1, 'year').format('Y');
      case '@mes-anterior@':
        return moment(this.lancamentoIntegracaoForm.get('data').value).subtract(1, 'month').locale('pt-br').format('MMM').toUpperCase();
      case '@mes-e-ano-anterior@':
        return moment(this.lancamentoIntegracaoForm.get('data').value).subtract(1, 'month').locale('pt-br').format('MMM Y').toUpperCase();
      default:
        return tag;
    }
  }


  //--------------------------------GROUPERS--------------------------------\\
  selectGrouper(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value != 0) {
      let eventInsert;
      if (event.option === undefined) {
        eventInsert = event;
      } else {
        eventInsert = event.option.value;
      }

      let exist = false;
      this.groupers.forEach((grouper, index: number) => {
        if (grouper.id === eventInsert.id) {
          exist = true;
          this.snackBar.open('Agrupador já selecionado!', 'atencao');
        }
        if (!exist && this.groupers.length === index + 1) {
          this.groupers.push(eventInsert);
          this.grouperInput.nativeElement.value = '';
          this.grouperCtrl.setValue(null);
        }
      });
      if (this.groupers.length === 0) {
        this.groupers.push(eventInsert);
        this.grouperInput.nativeElement.value = '';
        this.grouperCtrl.setValue(null);
      }
    }
  }
  removeGrouper(grouper, indx): void {
    this.groupers.splice(indx, 1);
  }
  private _filterG(value: any): any[] {
    if (typeof value === 'string') {
      const name = value;
      return value ? this.allGroupers.filter(fruit => fruit.name.toLowerCase().includes(name.toLowerCase())) : this.allGroupers;
    }
  }
  newGrouper() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroAgrupadoresComponent, modalOptions);
    modalRef.result.then((result) => {
      this.grouperProvider.getAllGroupers().then((groupers: GrouperDTOResponse[]) => {
        this.allGroupers = groupers;
        this.filteredGroupers = this.allGroupers;
        this.grouperCtrl.setValue('');
        this.initializeSearchSelect();
      }).catch((error) => {
        this.loading = false;
        this.snackBar.open('Erro ao carregar agrupadores. ' + error.error.error_description, 'erro');
      });
    }, () => {
    });
  }

  //--------------------------------RECEIPTS--------------------------------\\
  selectReceipt(event: MatAutocompleteSelectedEvent) {
    if (event.option.value != 0) {
      let eventInsert;
      if (event.option === undefined) {
        eventInsert = event;
      } else {
        eventInsert = event.option.value;
      }

      let exist = false;
      this.receipts.forEach((receipt, index: number) => {
        if (receipt.id === eventInsert.id) {
          exist = true;
          this.snackBar.open('Arquivo já selecionado!', 'atencao');
        }
        if (!exist && this.receipts.length === index + 1) {
          this.receipts.push(eventInsert);
          this.receiptInput.nativeElement.value = '';
          this.receiptCtrl.setValue(null);
        }
      });
      if (this.receipts.length === 0) {
        this.receipts.push(eventInsert);
        this.receiptInput.nativeElement.value = '';
        this.receiptCtrl.setValue(null);
      }
    }
  }
  removeReceipt(receipt, indx): void {
    this.receipts.splice(indx, 1);
  }
  private _filterR(value: any): any[] {
    if (typeof value === 'string') {
      const name = value;
      return value ? this.allReceipts.filter(fruit => fruit.description.toLowerCase().includes(name.toLowerCase())) : this.allReceipts;
    }
  }
  getFile(receipt) {
    this.loading = true;
    this.receiptProvider.downloadFile(receipt.file).then((value: any) => {
      const extension = receipt.file.split('.').pop();
      let blob;
      if (extension == 'pdf' || extension == 'xls' || extension == 'xlsx' || extension == 'doc' || extension == 'docx' || extension == 'txt') {
        let type = 'application/' + extension;
        blob = new Blob([value], { type: type });
      }
      else if (extension == 'png' || extension == 'jpg' || extension == 'jpeg') {
        let type = 'image/' + extension;
        blob = new Blob([value], { type: type });
      }
      else {
        blob = new Blob([value], { type: 'application/octet-stream' });
      }
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      this.loading = false;
    }).catch((error) => {
      this.loading = false;
      this.snackBar.open('Erro ao baixar arquivo. ' + error, 'erro');
    });
  }
}

class ImportedDoc {
  number: string;
  docType: string;
  propertyCode: string;
  property: Object;
  participantId: string;
  participant: Object;
  receiptFileIds: string[];
  posting: ImportedPosting;
}

class ImportedPosting {
  paymentDate: string;
  amountPaid: string;
  bankAccountId: number;
  bankAccount: Object
  accountPlanId: number;
  accountPlan: Object;
  postingReleaseType: string;
  importType: string;
  gouperIds: number[];
  historic: string;
  historicFormated: string;
}
