import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ExportProvider } from 'src/providers/export/export';
import { ExportUtil } from 'src/util/exportUtil';
import { Snackbar } from 'src/util/snackbar';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorUtil } from 'src/util/error';
import Swal from 'sweetalert2';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountPlanDTOResponse } from 'src/model/dto/AccountPlanDTOResponse';
import { AccountProvider } from 'src/providers/account/account';
import { CadastroPlanoDeContasComponent } from '../../plano-de-contas/cadastro-plano-de-contas/cadastro-plano-de-contas.component';


@Component({
  selector: 'app-cadastro-exportacoes',
  templateUrl: './cadastro-exportacoes.component.html',
  styleUrls: ['./cadastro-exportacoes.component.scss']
})
export class CadastroExportacoesComponent implements OnInit {

  protected _onDestroy = new Subject<void>();

  public enviado: boolean = false;
  public enviando: boolean = false;

  public editar: boolean = false;

  public exportacaoExistente: any = null;

  public exportacoesForm: FormGroup;
  public exportUtil = new ExportUtil();
  public defaultForeignExportations: any[] = this.exportUtil.ExportSystemForeignExportations;

  public accounts: any[] = [];
  public mappingFormArray: FormArray = new FormArray([]);
  public exportMappings: any[] = [];
  public accountMultiFilterCtrl: FormControl = new FormControl();
  public filteredAccountsMulti: ReplaySubject<AccountPlanDTOResponse[]> = new ReplaySubject<AccountPlanDTOResponse[]>(1);

  constructor(
    public activeModal: NgbActiveModal,
    public snackBar: Snackbar,
    public exportProvider: ExportProvider,
    public accountProvider: AccountProvider,
    public fb: FormBuilder,
    public erroUtil: ErrorUtil,
    public modalService: NgbModal
  ) { }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit() {
    this.initializeForm();
    this.editar ? this.getExportMappings() : '';
    this.getAccounts();
  }

  initializeForm() {
    this.exportacoesForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      foreignSystemName: new FormControl(null, Validators.required),
      foreignNameOther: new FormControl(null),
      localId: new FormControl(null, Validators.required),
    });
    this.editar ? this.setFormExistingExport() : '';
  }

  getExportMappings(){
    this.enviando = true;
    this.exportProvider.getExportMappingByExportId(this.exportacaoExistente.id).then((result : any) => {
      this.enviando = false;
      this.exportMappings = result;
      this.setExportMappingsToForm();
    }).catch((result: HttpErrorResponse) => {
      this.enviando = false;
      this.snackBar.openLong('Erro ao carregar mapeamentos! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'export'), 'erro');
    });
  }

  setFormExistingExport() {
    this.exportacoesForm.get('id').setValue(this.exportacaoExistente.id);
    this.exportacoesForm.get('name').setValue(this.exportacaoExistente.name);
    if (this.defaultForeignExportations.indexOf(this.exportacaoExistente.foreignSystemName) === -1) {
      this.exportacoesForm.get('foreignSystemName').setValue('OUTRO');
      this.exportacoesForm.get('foreignNameOther').setValue(this.exportacaoExistente.foreignSystemName);
    } else {
      this.exportacoesForm.get('foreignSystemName').setValue(this.exportacaoExistente.foreignSystemName);
    }
  }

  changeForeignExportation(event) {
    if (event.value === "OUTRO") {
      this.exportacoesForm.get('foreignNameOther').setValidators(Validators.required);
    } else {
      this.exportacoesForm.get('foreignNameOther').clearValidators();
      this.exportacoesForm.get('foreignNameOther').reset();
    }
  }

  submitForm() {
    this.enviado = true;
    this.enviando = true;
    if (!this.exportacoesForm.valid) {
      this.enviando = false;
      this.snackBar.openLong('Preencha todos os campos obrigatórios!', 'erro');
      return;
    }
    this.editar ? this.putExport() : this.postExport();
  }

  postExport() {
    this.exportProvider.postExport(this.setExportationObj()).then((result) => {
      this.enviando = false;
      if(this.mappingFormArray.controls.length > 0) {
        this.saveMappings(true);
      } else {
        this.snackBar.openLong("Sucesso! Exportação inserida com sucesso!", 'success');
        this.activeModal.close(result);
      }
    }).catch((result) => {
      this.enviando = false;
      this.snackBar.openLong('Não foi possível inserir! ' + result.error.error_description, 'erro');
    });
  }

  putExport() {
    this.exportProvider.putExport(this.setExportationObj()).then((result) => {
      this.enviando = false;
      this.snackBar.openLong("Sucesso! Exportação editada com sucesso!", 'success');
      this.activeModal.close(result);
    }).catch((result) => {
      this.enviando = false;
      this.snackBar.openLong('Não foi possível editar! ' + result.error.error_description, 'erro');
    });
  }

  setExportationObj() {
    let exportation = this.exportacoesForm.value;
    if (exportation.foreignSystemName === "OUTRO") {
      exportation.foreignSystemName = exportation.foreignNameOther;
    }
    return exportation;
  }

  getAccounts() {
    this.enviando = true;
    this.accountMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterAccountMulti();
    });
    this.accountProvider.getAllAccountsLeafs().then((result) => {
      this.accounts = this.convertPlanAccountObjectSearchSelect(result);
      this.filterAccountMulti();
      this.enviando = false;
    }).catch((result) => {
      this.enviando = false;
      this.snackBar.openLong('Não foi possível carregar as contas! ' + result.error.error_description, 'erro');
    });
  }

  convertPlanAccountObjectSearchSelect(planAccounts) {
    const newPlanAccounts: AccountPlanDTOResponse[] = [];
    planAccounts.forEach((categoria) => {
      const categoriaMod: any = {
        id: categoria.id,
        customId: categoria.customId,
        description: categoria.customId + ' - ' + categoria.description,
        featureType: categoria.featureType,
        participateRuralActivity: categoria.participateRuralActivity,
        releaseType: categoria.releaseType
      };
      newPlanAccounts.push(categoriaMod);
    });
    return newPlanAccounts.sort((a, b) => a.customId - b.customId);
  }

  addMappingFormGroup() {
    this.mappingFormArray.push(this.fb.group({
      id: [null],
      localId: [null, Validators.required],
      localModuleId: ["ACCOUNT_PLAN"],
      foreignCode: [null, Validators.required],
    }))
  }

  addAllAccounts() {
    this.enviando = true;
    this.accounts.forEach((account, index) => {
      if (index == this.accounts.length - 1) {
        this.enviando = false;
      }
      let accountExist = this.mappingFormArray.controls.some((control) => control.value.localId === account.id);
      if (!accountExist) {
        this.mappingFormArray.push(this.fb.group({
          id: [null],
          localId: [account.id],
          localModuleId: ["ACCOUNT_PLAN"],
          foreignCode: [null],
        }));
      }
    });
  }

  setExportMappingsToForm() {
    this.mappingFormArray.clear();
    this.exportMappings.forEach((mapping) => {
      this.mappingFormArray.push(this.fb.group({
        id: [mapping.id],
        localId: [mapping.localId, Validators.required],
        localModuleId: ["ACCOUNT_PLAN"],
        foreignCode: [mapping.foreignCode, Validators.required],
      }));
    });
  }

  checkUniqueMappings() {
    let accountIds = this.mappingFormArray.controls.map((control) => control.value.localId);
    return accountIds.some((accountId, index) => accountIds.indexOf(accountId) !== index);
  }

  saveMappings(close) {
    this.enviado = true;
    if (!this.mappingFormArray.valid) {
      this.snackBar.openLong('Preencha todos os campos obrigatórios!', 'erro');
      return;
    }
    if (this.checkUniqueMappings()) {
      this.snackBar.openLong('Não é possível repetir exportações!', 'erro');
      return;
    }
    this.enviando = true;
    let exportMappingArray = this.mappingFormArray.controls.map((control) => {
      let exportMappingObj = control.value;
      exportMappingObj['exportId'] = this.exportacoesForm.controls.id.value;
      return exportMappingObj;
    });
    this.exportProvider.postExportMapping(exportMappingArray).then(() => {
      this.enviando = false;
      this.snackBar.openLong('Mapeamentos salvos com sucesso!', 'success');
       close ? this.activeModal.close() : this.getAccounts();
    }).catch((result: HttpErrorResponse) => {
      this.enviando = false;
      this.snackBar.openLong('Erro ao salvar mapeamento! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'export'), 'erro');
    });
  }

  deleteExportMapping(i) {
    if (this.mappingFormArray.controls[i].value.id) {
      Swal.fire({
        title: 'Excluir mapeamento?',
        text: 'O mapeamento será excluído permanentemente!',
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.exportProvider.deleteExportMapping(this.mappingFormArray.controls[i].value.id).then((result) => {
            this.snackBar.openLong('Mapeamento excluído com sucesso!', 'success');
            this.mappingFormArray.removeAt(i);
          }).catch((result: HttpErrorResponse) => {
            this.snackBar.openLong('Erro ao excluir mapeamento! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'export'), 'erro');
          });
        }
      });
    } else {
      this.mappingFormArray.removeAt(i);
    }
  }

  protected filterAccountMulti() {
    if (!this.accounts) {
      return;
    }
    let search = this.accountMultiFilterCtrl.value;
    if (!search) {
      this.filteredAccountsMulti.next(this.accounts.slice());
    } else {
      search = search.toLowerCase();
      this.filteredAccountsMulti.next(
        this.accounts.filter(value => value.description.toLowerCase().indexOf(search) > -1)
          .sort((a, b) => a.description.indexOf(search) - b.description.indexOf(search))
      );
    }
  }

}