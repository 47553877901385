import {Component, Injectable, OnInit} from "@angular/core";
import {AccountProvider} from "../../../../providers/account/account";
import {BrMaskerIonicServices3} from "brmasker-ionic-3";
import {DateUtil} from "../../../../util/dateUtil";
import {Snackbar} from "../../../../util/snackbar";
import {ErrorUtil} from "../../../../util/error";
import {PropertyProvider} from "../../../../providers/company/property/property";
import {ExplorationProvider} from "../../../../providers/company/exploration/exploration";
import {PostingProvider} from "../../../../providers/bookkeeping/posting/posting";
import {Chart} from 'chart.js';

@Component({
  selector: 'app-dashboard-charts',
  template: ``
})

@Injectable()
export class DashBoardCharts implements OnInit {

  public charts = [];
  public chartData: any = {};

  public graphExpanded: boolean = false;
  public hidden: boolean = true;

  public loadingGraf1: boolean = false;
  public loadingGraf2: boolean = false;

  public isForExport: boolean = false;
  public exportando: boolean = false;

  public byAccountAux: any = [];

  public byAccount: any = [];

  public links = ['Receita', 'Despesa'];
  public activeLink = this.links[0];

  constructor(private accountProvider: AccountProvider,
              private brMasker: BrMaskerIonicServices3,
              public dateUtil: DateUtil,
              public snackBar: Snackbar,
              public errorUtil: ErrorUtil,
              private propertyProvider: PropertyProvider,
              private explorationProvider: ExplorationProvider,
              private postingProvider: PostingProvider) {
  }

  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

  generate() {
    this.loadingGraf1 = true;
    this.loadingGraf2 = true;
    this.exportando = true;
    this.graphExpanded = false;
    this.byAccount = [];

    this.formatByAccount();

    if (this.byAccount == null || this.byAccount.length <= 0) {
      this.loadingGraf1 = false;
      this.loadingGraf2 = false;
      this.exportando = false;
      this.hidden = true;
      return;
    }

    this.chartData = this.setChartData(this.byAccount);
    this.generateCharts();

    this.loadingGraf1 = false;
    this.loadingGraf2 = false;
    this.exportando = false;

    if (this.byAccount != null && this.byAccount.length > 0) {
      this.graphExpanded = true;
      this.hidden = false;
    }
  }

  private formatByAccount() {
    if (this.byAccountAux == null) return;
    this.byAccountAux.forEach(element => {
      if (element != null) {
        this.byAccount = [...this.byAccount, ...this.filtrarFolhas(element)];
      }
    });

    this.byAccount.sort((a, b) => {
      if (+a.customId < +b.customId) {
        return -1;
      }
      if (+a.customId > +b.customId) {
        return 1;
      }
      return 0;
    });

    let max = 0;
    let min = 0;
    this.byAccount.map((data) => {
      if (data.months) data.total = data.months[12];
      delete data['@type'];
      delete data['months'];
      if (data.total > 0) {
        max += data.total;
      } else if (data.total < 0) {
        min += data.total;
      }
    });

    if (this.byAccount.length > 0) {
      this.byAccount.push({
        customId: 'max',
        description: 'TOTAL',
        total: max
      }, {
        customId: 'min',
        description: 'TOTAL',
        total: min
      });
    }
  }

  changeTab(tab: 'Receita' | 'Despesa') {
    this.activeLink = tab;
    this.loadingGraf1 = true;
    this.loadingGraf2 = true;

    this.chartData = this.setChartData(this.byAccount);
    this.generateCharts();

    this.loadingGraf1 = false;
    this.loadingGraf2 = false;

    if (this.byAccount != null && this.byAccount.length > 0) {
      this.hidden = false;
    }
  }

  filtrarFolhas(node: any) {
    if (node == null) return [];
    // Passo 2: Verifica se o nó atual é uma folha
    if (node["@type"] === "Leaf") {
      return [node];
    }

    // Passo 3 e 4: Processa os filhos, se houver, e concatena os resultados
    if (node.children) {
      return node.children.reduce((acc, child) => {
        const result = this.filtrarFolhas(child);
        if (result.length > 0) { // Verifica se o resultado não é vazio antes de concatenar
          return acc.concat(result);
        }
        return acc;
      }, []);
    }

    // Retorna um array vazio se o nó atual não é uma folha e não tem filhos
    return [];
  }

  format(valorTotal: number) {
    if (valorTotal == 0) {
      return "0,00"
    }
    if (valorTotal != undefined) {
      return ((valorTotal.toFixed(2)).toString()).replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  }

  destroyCharts() {
    if (this.charts.length > 0) {
      this.charts.forEach((chart) => {
        chart.destroy();
      })
    }
    document.getElementById('divChart0').style.height = "0px";
    document.getElementById('divChart1').style.height = "0px";
  }

  setChartData(byAccount) {
    let byAccountChart = {
      labels: [],
      labels2: [],
      pie: [],
      bar: []
    }
    if (byAccount.length > 0) {
      let sum = byAccount.find((element) => {
        if (this.activeLink == 'Receita') {
          return element.customId == 'max'
        }
        if (this.activeLink == 'Despesa') {
          return element.customId == 'min'
        }
      }).total
      byAccount = byAccount.filter((element) => {
        if (this.activeLink == 'Receita') {
          return element.total > 0 && element.description != 'TOTAL'
        }
        if (this.activeLink == 'Despesa') {
          return element.total < 0 && element.description != 'TOTAL'
        }
      });
      byAccount.sort((a, b) => (Math.abs(a.total) < Math.abs(b.total)) ? 1 : ((Math.abs(b.total) < Math.abs(a.total)) ? -1 : 0));
      if (byAccount.length > 10) {
        let byAccountAux = [];
        byAccountAux = byAccount.slice(10, byAccount.length);
        byAccount = byAccount.slice(0, 9);
        byAccount.push({
          customId: 'Outros',
          description: 'OUTROS',
          total: byAccountAux.reduce((acc, item) => acc + item.total, 0)
        });
      }
      byAccountChart = {
        labels: byAccount.map(a => a.customId == 'Outros' ? a.customId : (a.customId + ' - ' + a.description)),
        labels2: byAccount.map(a => a.customId),
        pie: byAccount.map(a => Math.abs((a.total / sum * 100)).toFixed(2)),
        bar: byAccount.map(a => Math.abs(a.total))
      }
    }
    return byAccountChart;
  }

  // geração random de cor baseado em quantos itens o gráfico vai ter
  generateColors(value) {

    let pallete = [
      `rgba(${255},${211},${79},1)`,
      `rgba(${25},${197},${148},0.7)`,
      `rgba(${237},${85},${101},0.93)`,
      `rgba(${28},${132},${198},0.78)`,
      `rgba(${187},${107},${217},0.85)`,
      `rgba(${242},${152},${73},0.95)`,
      `rgba(${109},${146},${227},0.89)`,
      `rgba(${111},${63},${170},0.67)`,
      `rgba(${59},${214},${152},0.84)`,
      `rgba(${167},${166},${214},0.84)`,
    ]

    return pallete;
  }

  generateCharts() {
    this.destroyCharts();

    let height = this.isForExport ? "400px" : "300px";
    document.getElementById('divChart0').style.height = height;
    document.getElementById('divChart1').style.height = height;

    let chartBackgroundColors = this.generateColors(this.chartData.labels.length);

    let valores = this.chartData.bar;
    this.charts[0] = new Chart('myChart0', {
      type: 'pie',
      showTooltips: true,
      data: {
        labels: this.chartData.labels,
        datasets: [
          {
            data: this.chartData.pie,
            backgroundColor: chartBackgroundColors,
            borderColor: 'rgba(0, 0, 0, 1)',
            borderWidth: 0.1,
            suffix: "%",
            polyline: {
              color: "gray",
              labelColor: "gray",
              formatter: (value) => `${value}`
            }
          },
        ]
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        rotation: this.isForExport ? 160 : 0,
        showAllTooltips: true,
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            title: function (tooltipItems) {
              return labels[tooltipItems[0].index];
            },
            label: function (tooltipitems, data) {
              return `${data.datasets[0].data[tooltipitems.index]}% (${new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(valores[tooltipitems.index])})`;
            }
          }
        }
      },
      plugins: {
        afterDraw: (chart) => {
          if (!this.isForExport) return;
          const ctx = chart.chart.ctx;
          ctx.save();
          ctx.font = "12px 'Averta Std CY'";
          const leftLabelCoordinates = [];
          const rightLabelCoordinates = [];
          const chartCenterPoint = {
            x: (chart.chartArea.right - chart.chartArea.left) / 2 + chart.chartArea.left,
            y: (chart.chartArea.bottom - chart.chartArea.top) / 2 + chart.chartArea.top
          };

          chart.config.data.labels.forEach((label, i) => {
            const meta = chart.getDatasetMeta(0);
            const arc = meta.data[i];
            const dataset = chart.config.data.datasets[0];

            const centerPoint = arc.getCenterPoint();
            const model = arc._model;

            const angle = Math.atan2(
              centerPoint.y - chartCenterPoint.y,
              centerPoint.x - chartCenterPoint.x
            );

            const point2X = chartCenterPoint.x + Math.cos(angle) * (model.outerRadius - 15);
            let point2Y = chartCenterPoint.y + Math.sin(angle) * (model.outerRadius - 15);

            let suitableY = this.getSuitableY(point2Y, rightLabelCoordinates, "right");
            if (point2X < chartCenterPoint.x) {
              suitableY = this.getSuitableY(point2Y, leftLabelCoordinates, "left");
            }
            point2Y = suitableY;

            let value = `${this.chartData.labels2[i]} - ${dataset.data[i]}% (${new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(valores[i])})`
            let edgePointX = point2X < chartCenterPoint.x ? 10 : chart.width - 10;

            if (point2X < chartCenterPoint.x) {
              leftLabelCoordinates.push(point2Y);
            } else {
              rightLabelCoordinates.push(point2Y);
            }
            //DRAW CODE
            // first line: connect between arc's center point and outside point
            ctx.strokeStyle = 'rgba(0, 0, 0, 1)';
            ctx.lineWidth = 1.5;
            ctx.beginPath();
            ctx.moveTo(centerPoint.x, centerPoint.y);
            ctx.lineTo(point2X, point2Y);
            ctx.stroke();
            // second line: connect between outside point and chart's edge
            ctx.beginPath();
            ctx.moveTo(point2X, point2Y);
            ctx.lineTo(edgePointX, point2Y);
            ctx.stroke();
            //fill custom label
            const labelAlignStyle =
              edgePointX < chartCenterPoint.x ? "left" : "right";
            const labelX = edgePointX;
            const labelY = point2Y;
            ctx.textAlign = labelAlignStyle;
            ctx.textBaseline = "bottom";

            ctx.fillStyle = 'rgba(0, 0, 0, 1)';
            ctx.fillText(value, labelX, labelY);
          });
          // ctx.restore();
        }
      }
    });

    let labels = this.chartData.labels;
    this.charts[1] = new Chart('myChart1', {
      type: 'bar',
      data: {
        labels: this.chartData.labels2,
        datasets: [{
          data: this.chartData.bar,
          backgroundColor: chartBackgroundColors,
          borderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 0.1,
        }]
      },
      options: {
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: function (value, index, values) {
                return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
              }
            }
          }],
          y: {
            beginAtZero: true,
          },
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            title: function (tooltipItems) {
              return labels[tooltipItems[0].index];
            },
            label: function (tooltipitems, data) {
              return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(data.datasets[0].data[tooltipitems.index]);
            }
          }
        }
      }
    });
  }

  getSuitableY(y, yArray = [], direction) {
    let result = y;
    yArray.forEach((existedY) => {
      if (existedY - 15 < result && existedY + 15 > result) {
        if (direction === "right") {
          result = existedY + 15;
        } else {
          result = existedY - 15;
        }
      }
    });

    return result;
  };
}
