import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AccountProvider} from '../../../../providers/account/account';
import {BankAccountProvider} from '../../../../providers/company/bank_account/bank_account';
import {MemberProvider} from '../../../../providers/company/member/member';
import {ContaBancariaDTO} from '../../../../model/dto/ContaBancariaDTO';
import {PessoaFiscalDTO} from '../../../../model/dto/PessoaFiscalDTO';
import {BrMaskerIonicServices3} from 'brmasker-ionic-3';
import {PostingProvider} from '../../../../providers/bookkeeping/posting/posting';
import {Bookkeeping} from '../../../../model/dto/bookkeping/Bookkeeping';
import {HttpErrorResponse} from '@angular/common/http';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';
import 'moment-timezone';
import {GridComponent, GroupSettingsModel, PdfExportProperties, SelectionService} from '@syncfusion/ej2-angular-grids';
import {DocumentsBookkepingMapper} from '../../../../model/dto/mapper/documentsBookkepingMapper';
import {ExplorationDTOResponse} from '../../../../model/dto/ExplorationDTOResponse';
import {ExplorationProvider} from '../../../../providers/company/exploration/exploration';
import {ImovelDTO} from '../../../../model/dto/ImovelDTO';
import {AccountPlanDTOResponse} from '../../../../model/dto/AccountPlanDTOResponse';
import {ClickEventArgs} from '@syncfusion/ej2-navigations';
import {ExcelExportProperties} from '@syncfusion/ej2-grids';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {ErrorUtil} from '../../../../util/error';
import { DateUtil } from 'src/util/dateUtil';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { PropertyProvider } from 'src/providers/company/property/property';
import { CadastrarLancamentoComponent } from '../../financeiro/cadastrar-lancamento/cadastrar-lancamento.component';
import {ReportsUtil} from "../../../../util/Reports";
import {ModalExportComponent} from "../modal-export/modal-export.component";

class Saldo {
  idConta: number;
  jan: number;
  fev: number;
  mar: number;
  abr: number;
  mai: number;
  jun: number;
  jul: number;
  ago: number;
  set: number;
  out: number;
  nov: number;
  dez: number;
  total: number;
}

@Component({
  selector: 'app-livro-razao',
  templateUrl: './livro-razao.component.html',
  styleUrls: ['./livro-razao.component.scss'],
  providers: [SelectionService,
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class LivroRazaoComponent implements OnInit {

  @ViewChild('grid', {static: false}) public grid: GridComponent;

  consultando: boolean = false;
  anual = true;
  personalizado = false;
  year: number;
  years: any[];
  deducao: any = null;
  months = [{name:'JAN', num: '01', max: "31"}, {name:'FEV', num: '02', max: "28"}, {name:'MAR', num: '03', max: '31'}, {name:'ABR', num: '04', max: '30'}, {name:'MAI', num: '05', max: '31'},{name: 'JUN', num:'06', max: '30'},{ name:'JUL', num: '07', max: '31'},
   {name:'AGO', num: '08', max: '31'},{name: 'SET', num:'09', max: '30'}, {name:'OUT', num: '10', max: '31'}, {name:'NOV', num: '11', max: '30'}, {name:'DEZ', num: '12', max: '31'}];

  contas: ContaBancariaDTO[];
  socios: PessoaFiscalDTO[];
  lancamentos: any = [];
  exploration: ExplorationDTOResponse[];
  planosDeConta: AccountPlanDTOResponse[];

  members = new FormControl();
  contasMovimento = new FormControl();
  contasPlano = new FormControl();

  groupOptions: GroupSettingsModel;
  initialSort: any;
  month: any;
  toolbarOptions: any[];
  agregrates: any[];
  public property = new FormControl();
  changed = false
  public accountMultiCtrl: FormControl = new FormControl();
  public accountMultiFilterCtrl: FormControl = new FormControl();
  public filteredAccountsMulti: ReplaySubject<AccountPlanDTOResponse[]> = new ReplaySubject<AccountPlanDTOResponse[]>(1);

  // tslint:disable-next-line:variable-name
  protected _onDestroy = new Subject<void>();
  public erro: boolean;
  public errorMessage: any;
  public imoveis
  // tslint:disable-next-line: no-use-before-declare
  public dateRange = new ConsultDate();

  constructor(private accountProvider: AccountProvider,
              private contasProvider: BankAccountProvider,
              public modalService: NgbModal,
              private brMasker: BrMaskerIonicServices3,
              private explorationProvider: ExplorationProvider,
              private documentsBookkepingMapper: DocumentsBookkepingMapper,
              private postingProvider: PostingProvider,
              private errorUtil: ErrorUtil,
              private memberProvider: MemberProvider,
              private dateUtil: DateUtil,
              private propertyProvider: PropertyProvider,
              public reportsUtil: ReportsUtil,
              // tslint:disable-next-line: variable-name
              private _adapter: DateAdapter<any>) {
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit() {
    this.groupOptions = {showDropArea: false, showGroupedColumn: false, columns: ['accountDescription'],disablePageWiseAggregates: true};

    this.accountMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      // tslint:disable-next-line: deprecation
      .subscribe(() => {
        this.filterAccountMulti();
      });

    this.toolbarOptions = [
      {text: 'Exportar', tooltipText: 'Exportar', prefixIcon: 'e-pdfexport', id: 'export'},
      'ColumnChooser'
    ];
    this._adapter.setLocale('pt-BR');
    this.month = moment(localStorage.getItem('workDate')).locale('pt-BR').format('MMM').toUpperCase();
    this.year = +moment(localStorage.getItem('workDate')).format('YYYY');
    this.years = getYears(+moment().format('YYYY') - 20);
    const workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
    // tslint:disable-next-line: deprecation
    this.dateRange.startDate = moment.tz(workDate, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
    // tslint:disable-next-line: deprecation
    this.dateRange.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('year').format('YYYY-MM-DD');
    this.deducao =null;

    this.initializeData().then(result => {
        this.consultando = false;
    }).catch((result) => {
      this.errorUtil.checkErrorStatus(result, result.status, result.error, 'relatorios-consolidacao');
      console.error(result);
      this.consultando = false;
      this.erro = true;
    });

    this.agregrates = [{
      columns: [
        {
          type: 'Sum',
          field: 'amountPaid',
          format: 'C2',
          groupFooterTemplate: '<b><font color="gray">${Sum}</font></b>'
        }],
    }];
  }

  initializeData() {
    return new Promise((accept, reject) => {
      this.contasProvider.getAllBanksAccounts().then((accounts: ContaBancariaDTO[]) => {
        this.contas = accounts;
        this.memberProvider.getAllMembers().then((members: PessoaFiscalDTO[]) => {
          this.socios = members;
          this.accountProvider.getAllAccountsLeafs().then((categorias: []) => {
            this.planosDeConta = this.convertPlanAccountObjectSearchSelect(categorias);
            this.filteredAccountsMulti.next(this.planosDeConta.slice());
            this.explorationProvider.getByCompany().then((exploration: ExplorationDTOResponse[]) => {
              this.exploration = exploration;
              this.propertyProvider.getAllProperty().then((properties: ImovelDTO[]) => {
                this.imoveis = properties;
              }).catch(result => {
                this.errorUtil.checkErrorStatus(result, result.status, result.error, 'relatorios-consolidacao');
              });
              accept(true);
            });
          });
        });
      }).catch((result: HttpErrorResponse) => {
        this.errorUtil.checkErrorStatus(result, result.status, result.error, 'relatorios-consolidacao');
        reject(result);
      });
    });
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredAccountsMulti.pipe(take(1), takeUntil(this._onDestroy))
      // tslint:disable-next-line: deprecation
      .subscribe(val => {
        if (selectAllValue) {
          this.accountMultiCtrl.patchValue(val);
        } else {
          this.accountMultiCtrl.patchValue([]);
        }
      });
  }

  protected filterAccountMulti() {
    if (!this.planosDeConta) {
      return;
    }
    // get the search keyword
    let search = this.accountMultiFilterCtrl.value;
    if (!search) {
      this.filteredAccountsMulti.next(this.planosDeConta.slice());
    } else {
      // filter the planAccount
      search = search.toLowerCase();
      this.filteredAccountsMulti.next(
        this.planosDeConta.filter(value => value.description.toLowerCase().indexOf(search) > -1)
          .sort((a, b) => a.description.indexOf(search) - b.description.indexOf(search))
      );
    }
  }

  convertPlanAccountObjectSearchSelect(planAccounts) {
    const newPlanAccounts: AccountPlanDTOResponse[] = [];
    planAccounts.forEach((categoria) => {
      const categoriaMod: any = {
        id: categoria.id,
        customId: categoria.customId,
        description: categoria.customId + ' - ' + categoria.description,
        featureType: categoria.featureType,
        participateRuralActivity: categoria.participateRuralActivity,
        releaseType: categoria.releaseType
      };
      newPlanAccounts.push(categoriaMod);
    });
    return newPlanAccounts.sort((a, b) => a.customId - b.customId);
  }

  getLancamentos(ano, mes, contas, members, planoDecontas) {
    //console.log(ano, mes, contas, members, planoDecontas, this.property.value)
    let json = {
      bankAccountIds: [],
      propertyCodes: [],
      cpfCnpjs: [],
      accountPlanIds: [],
      deductible: this.deducao
    }
    if(this.property.value){
      this.property.value.forEach(element => {
        //console.log(element)

        json.propertyCodes.push(element.propertyCode)
      })
    }

    if(contas){
      contas.forEach(element => {
      //  console.log(element)
      json.bankAccountIds.push(element.id)
    //  json.propertyCodes.push(element.code)
    })
    }
   if(members){
      members.forEach(element => {
    //    console.log("aaaaazzz",element)
      json.cpfCnpjs.push(element.cpfCNPJ)
    })
   }
   if(planoDecontas){
      planoDecontas.forEach(element => {
    //    console.log("aaaaa",element)

      json.accountPlanIds.push(element.id)
    })
   }
   let startMonth = ano + '-' + '01' + '-01';
   let endMonth = ano + '-' + '12' + '-31';
   if (this.personalizado) {
     startMonth = this.dateRange.startDate;
     endMonth = this.dateRange.endDate;
   }else if(!this.personalizado && !this.anual){
    startMonth =  ano + '-' + this.month.num + '-01';
    endMonth = ano + '-' + this.month.num + '-'+ this.month.max;
  }
   this.consultando = true
    this.postingProvider.getFilteredPostings( startMonth,endMonth, json ).then((result:any) => {
      //console.log(result)
      result.forEach(element => {
        element.historic = element.historic.replaceAll("\\N", "");
        element.historic = element.historic.replaceAll("\\n", "");
        if((element.historic.includes('modelo') || element.historic.includes('MODELO')) && (element.historic.includes('historico') || element.historic.includes('HISTORICO'))){
         //console.log("OIII")
         let his = JSON.parse(element.historic)
         //console.log("OIII", his)
         if(his.historico)
          element.historic = his.historico
        if(his.HISTORICO)
        element.historic = his.HISTORICO

        }
      })
      this.lancamentos = result
      this.consultando = false
      this.erro = false
    }).catch(error => {
      console.log(error)
      this.consultando = false
        this.erro = true
    })
      this.groupOptions = {showDropArea: false, showGroupedColumn: false, columns: ['accountDescription'],disablePageWiseAggregates: true};

   /* this.consultando = true;
    this.year = ano;
    try {
    this.generateSaldos(ano, mes, contas, members, planoDecontas);
    } catch (exception) {
      console.error('Erro ao gerar saldos', exception);
    }
    this.initialSort = {
      columns: [{field: 'paymentDate', direction: 'Ascending'}]
    }; **/

  }
  selectAllAcounts(){
    //console.log(this.contasPlano.value, this.planosDeConta)
    if(this.contasPlano.value.length > 1){
      this.contasPlano.setValue([])
    }else{
          this.contasPlano.setValue(this.planosDeConta)

    }
  }
  selectAllMembers(){
    //console.log(this.members.value, this.socios)
    if(this.members.value.length > 1){
      this.members.setValue([])
    }else{
          this.members.setValue(this.socios)

    }
  }
  selectAllAccountMovements(){
    //console.log(this.contasMovimento.value, this.contas)
    if(this.contasMovimento.value.length > 1){
      this.contasMovimento.setValue([])
    }else{
          this.contasMovimento.setValue(this.contas)

    }
  }
  selectAllImoveis(){
    //console.log(this.contasMovimento.value, this.imoveis)
    if(this.property.value.length > 1){
      this.property.setValue([])
    }else{
          this.property.setValue(this.imoveis)

    }
  }
  generateSaldos(ano, mes, contas, members, planoDecontas) {
    const lancamentos: Bookkeeping[] = [];
    let startMonth = ano + '-' + '01' + '-01';
    let endMonth = ano + '-' + '12' + '-31';
    if (this.personalizado) {
      startMonth = this.dateRange.startDate;
      endMonth = this.dateRange.endDate;
    }else if(!this.personalizado && !this.anual){
      startMonth = this.dateRange.startDate;
      endMonth = this.dateRange.endDate;
    }

    this.postingProvider.getAllPosting(startMonth, endMonth).then((lancamentosAux: Bookkeeping[]) => {
      lancamentosAux.forEach(lancamento => {
        lancamento.historic =  lancamento.historic.replace(new RegExp('[–]', 'gi'), '-').replace(/|/g,'').replace(/–/g,' ').replace(/"/g,' ').replace(/'/g,' ').replace(/\n\r/g,' ');
      });
      // tslint:disable-next-line:one-variable-per-declaration
      const cars = this.filterLancamentos(this.documentsBookkepingMapper.arrayToClientModel(lancamentosAux),
        contas, this.getPropertys(members, this.exploration), this.deducao, planoDecontas, members),
        result = this.filterLancamentos(this.documentsBookkepingMapper.arrayToClientModel(lancamentosAux),
          // tslint:disable-next-line:only-arrow-functions
          contas, this.getPropertys(members, this.exploration), this.deducao, planoDecontas, members).reduce(function(r, a) {
          r[a.account.id] = r[a.account.id] || [];
          r[a.account.id].push(a);
          return r;
        }, Object.create(null));

      const saldostodos = Object.entries(result);

      if (saldostodos.length === 0) {
        this.lancamentos = [];
        this.consultando = false;
      } else {
        saldostodos.forEach((account: any) => {
          const saldo = new Saldo();
          saldo.jan = 0;
          saldo.fev = 0;
          saldo.mar = 0;
          saldo.abr = 0;
          saldo.mai = 0;
          saldo.jun = 0;
          saldo.jul = 0;
          saldo.ago = 0;
          saldo.set = 0;
          saldo.out = 0;
          saldo.nov = 0;
          saldo.dez = 0;
          saldo.total = 0;
          saldo.idConta = account[0];
          // tslint:disable-next-line:no-shadowed-variable
          account[1].forEach(account => {

            saldo.total = account.amountPaid + saldo.total;

            let month = moment(account.paymentDate).format('MM');

            switch (month) {
              case '01':
                saldo.jan = account.amountPaid + saldo.jan;
                break;
              case '02':
                saldo.fev = account.amountPaid + saldo.fev;
                break;
              case '03':
                saldo.mar = account.amountPaid + saldo.mar;
                break;
              case '04':
                saldo.abr = account.amountPaid + saldo.abr;
                break;
              case '05':
                saldo.mai = account.amountPaid + saldo.mai;
                break;
              case '06':
                saldo.jun = account.amountPaid + saldo.jun;
                break;
              case '07':
                saldo.jul = account.amountPaid + saldo.jul;
                break;
              case '08':
                saldo.ago = account.amountPaid + saldo.ago;
                break;
              case '09':
                saldo.set = account.amountPaid + saldo.set;
                break;
              case '10':
                saldo.out = account.amountPaid + saldo.out;
                break;
              case '11':
                saldo.nov = account.amountPaid + saldo.nov;
                break;
              case '12':
                saldo.dez = account.amountPaid + saldo.dez;
                break;

              default:
                month = '01';
            }
          });

          saldo.fev = saldo.fev + saldo.jan;
          saldo.mar = saldo.mar + saldo.fev;
          saldo.abr = saldo.abr + saldo.mar;
          saldo.mai = saldo.mai + saldo.abr;
          saldo.jun = saldo.jun + saldo.mai;
          saldo.jul = saldo.jul + saldo.jun;
          saldo.ago = saldo.ago + saldo.jul;
          saldo.set = saldo.set + saldo.ago;
          saldo.out = saldo.out + saldo.set;
          saldo.nov = saldo.nov + saldo.out;
          saldo.dez = saldo.dez + saldo.nov;
          account.push(saldo);

        });

        saldostodos.forEach((account: any) => {
          const balanco = new Saldo();
          balanco.jan = 0;
          balanco.fev = account[2].jan;
          balanco.mar = account[2].fev;
          balanco.abr = account[2].mar;
          balanco.mai = account[2].abr;
          balanco.jun = account[2].mai;
          balanco.jul = account[2].jun;
          balanco.ago = account[2].jul;
          balanco.set = account[2].ago;
          balanco.out = account[2].set;
          balanco.nov = account[2].out;
          balanco.dez = account[2].nov;
          balanco.total = account[2].total;

          // tslint:disable-next-line:only-arrow-functions
          account[1].sort(function(a, b) {
            return (a.paymentDate) < (b.paymentDate) ? -1 : (a.paymentDate) > (b.paymentDate) ? 1 : 0;
          });

          if (!this.anual && !this.personalizado) {
            const date = this.year + '-' + this.returnMonth() + '-' + '01';
            // tslint:disable-next-line: deprecation
            const startOfMonth =  moment.tz(date, 'America/Sao_Paulo').startOf('month').subtract('day', 1).format('YYYY-MM-DD');
            // tslint:disable-next-line: deprecation
            const endOfMonth =  moment.tz(date, 'America/Sao_Paulo').endOf('month').add('day', 1).format('YYYY-MM-DD');
            account[1] = account[1].filter(obj => moment.tz(obj.paymentDate, 'America/Sao_Paulo').isBetween(startOfMonth, endOfMonth));
          }

          account[1].forEach((accountLancamento, index) => {

            if (this.anual) {
              if (index === 0) {
                accountLancamento.balance = accountLancamento.amountPaid;
                balanco.total = accountLancamento.balance;
              } else {
                accountLancamento.balance = accountLancamento.amountPaid + balanco.total;
                balanco.total = accountLancamento.balance;
              }
            } else {
              switch (this.month.name) {
                case 'JAN':
                  balanco.jan = this.generateSaldo(index, accountLancamento, balanco.jan);
                  break;
                case 'FEV':
                  balanco.fev = this.generateSaldo(index, accountLancamento, balanco.fev);
                  break;
                case 'MAR':
                  balanco.mar = this.generateSaldo(index, accountLancamento, balanco.mar);
                  break;
                case 'ABR':
                  balanco.abr = this.generateSaldo(index, accountLancamento, balanco.abr);
                  break;
                case 'MAI':
                  balanco.mai = this.generateSaldo(index, accountLancamento, balanco.mai);
                  break;
                case 'JUN':
                  balanco.jun = this.generateSaldo(index, accountLancamento, balanco.jun);
                  break;
                case 'JUL':
                  balanco.jul = this.generateSaldo(index, accountLancamento, balanco.jul);
                  break;
                case 'AGO':
                  balanco.ago = this.generateSaldo(index, accountLancamento, balanco.ago);
                  break;
                case 'SET':
                  balanco.set = this.generateSaldo(index, accountLancamento, balanco.set);
                  break;
                case 'OUT':
                  balanco.out = this.generateSaldo(index, accountLancamento, balanco.out);
                  break;
                case 'NOV':
                  balanco.nov = this.generateSaldo(index, accountLancamento, balanco.nov);
                  break;
                case 'DEZ':
                  balanco.dez = this.generateSaldo(index, accountLancamento, balanco.dez);
                  break;
              }
            }

            lancamentos.push(accountLancamento);
          });
          this.consultando = false;
          this.lancamentos = (lancamentos);

        });
      }
      this.erro = false;
    }).catch((result) => {
      this.lancamentos = [];
      this.errorUtil.checkErrorStatus(result, result.status, result.error, 'relatorios-razao');
      console.error(result);
      this.consultando = false;
      this.erro = true;
      this.errorMessage = result.message;
    });


  }

  generateSaldo(index, accountLancamento, balanco) {
    if (index === 0) {
      accountLancamento.balance = accountLancamento.amountPaid + balanco;
      balanco = accountLancamento.balance;
    } else {
      accountLancamento.balance = accountLancamento.amountPaid + balanco;
      balanco = accountLancamento.balance;
    }
    return balanco;
  }

  getPropertys(members: PessoaFiscalDTO[], explorations: ExplorationDTOResponse[]): ImovelDTO[] {
    const propertyFiltered: Array<ImovelDTO> = [];
    if (members != null) {
      members.forEach((member: PessoaFiscalDTO) => {
        explorations.forEach((exploration: ExplorationDTOResponse) => {
          if (exploration.member.cpfCNPJ === member.cpfCNPJ) {
            if (propertyFiltered.findIndex(val => val.propertyCode === exploration.property.propertyCode) < 0) {
              propertyFiltered.push(exploration.property);
            }
          }
        });
      });
    }
    return propertyFiltered;
  }

  filterLancamentos(lancamentos: Bookkeeping[], contas, imoveis: ImovelDTO[], dedutiveis, planoDeContas, members) {

    const lancamentosAux = [];
    if (dedutiveis === 'DEDUTIVEIS') {
      lancamentos = lancamentos.filter(obj => obj.postingReleaseType !== 'NONE');
    }
    if (dedutiveis === 'NAODEDUTIVEIS') {
      lancamentos = lancamentos.filter(obj => obj.postingReleaseType === 'NONE');
    }
    if (lancamentos.length !== 0) {
      lancamentos.forEach((lancamento: Bookkeeping) => {
        if (this.filterLancamentoAccountPlano(this.filterLancamentoBankAccount(this.filterLancamentoMember(lancamento,
          imoveis, members), contas), planoDeContas) != null) {
          lancamentosAux.push(lancamento);
        }
      });
    }
    return lancamentosAux;
  }

  filterLancamentoMember(lancamento, imoveis, members) {
    if (imoveis != null) {
      if (imoveis.length !== 0) {
        if (imoveis.findIndex(val => val.propertyCode === lancamento.doc.property.propertyCode) < 0) {
          return null;
        } else {
          let lancamentoValue = 0;
          const explorations = this.exploration.filter(val => val.property.propertyCode === lancamento.doc.property.propertyCode);
          let explorationsFiltered = [];
          members.forEach((member) => {
            const explorationFilter = explorations.filter(val => val.member.cpfCNPJ === member.cpfCNPJ && val.apportionment);
            explorationsFiltered = explorationsFiltered.concat(explorationFilter);
          });
          explorationsFiltered.forEach((exploration) => {
            // tslint:disable-next-line: max-line-length
            lancamentoValue = lancamentoValue + lancamento.amountPaid * (LivroRazaoComponent.getPercentage(exploration, explorations) / 100);
          });
          lancamento.amountPaid = lancamentoValue;
          return lancamento;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }


  // tslint:disable-next-line: member-ordering
  private static getPercentage(exploration, explorations) {
    const apportionmentPercentage = explorations
      .filter(obj => obj.apportionment)
      .reduce((total, valor) => total + valor.percentage, 0);
    return (exploration.percentage * 100) / apportionmentPercentage;
  }


  filterLancamentoAccountPlano(lancamento, planoDeContas) {
    if (planoDeContas != null && lancamento != null) {
      if (planoDeContas.length !== 0) {
        if (planoDeContas.findIndex(val => val.id === lancamento.account.id) < 0) {
          return null;
        } else {
          return lancamento;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  filterLancamentoBankAccount(lancamento, contas) {
    if (lancamento != null) {
      if (contas != null) {
        if (contas.length !== 0) {
          if (lancamento.bankAccount != null) {
            if (contas.findIndex(val => val.id === lancamento.bankAccount.id) < 0) {
              return null;
            } else {
              return lancamento;
            }
          } else {
            return null;
          }
        } else {
          return lancamento;
        }
      } else {
        return lancamento;
      }
    } else {
      return null;
    }
  }

  chosenYearHandler(ano: any) {
    this.year = ano;
    //console.log(this.month)
   // this.tabsTypeFilter();
  }
  rowSelected(x){
    //console.log(x.postingId)
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastrarLancamentoComponent, modalOptions);
    modalRef.componentInstance.lancamentoExistenteID =Number(x.docId);
    modalRef.result.then((result) => {
      //console.log("result ", result);
      if (result) {
        this.tabsTypeFilter();
      }
    }, (result) => {
      if (result) {
        this.tabsTypeFilter();
      }
    });
  }
  tabsTypeFilter() {
    this.changed=false
    let members;   // se não existir um produtor selecionado, consulta com todos.
    let planoDeContas;   // se não existir um plano de contas selecionado, consulta com todos.

    if (this.members.value == null || this.members.value.length === 0) {
      members = this.socios;
    } else {
      members = this.members.value;
    }

    if (this.contasPlano.value == null || this.contasPlano.value.length === 0) {
      planoDeContas = this.planosDeConta;
    } else {
      planoDeContas = this.contasPlano.value;
    }
    //console.log("SPFC",this.year, this.month, this.contasMovimento.value, members, planoDeContas)
    this.getLancamentos(this.year, this.month, this.contasMovimento.value, members, planoDeContas);
  }

  returnMonth(): string {
    switch (this.month.name) {
      case 'JAN':
        return '01';
      case 'FEV':
        return '02';
      case 'MAR':
        return '03';
      case 'ABR':
        return '04';
      case 'MAI':
        return '05';
      case 'JUN':
        return'06';
      case 'JUL':
        return '07';
      case 'AGO':
        return '08';
      case 'SET':
        return '09';
      case 'OUT':
        return '10';
      case 'NOV':
        return '11';
      case 'DEZ':
        return '12';
      default:
        return '01';
    }
  }

  toolbarClick(args: ClickEventArgs): void {
    if (args.item.id === 'export') {
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : '',
      };
      const modalRef = this.modalService.open(ModalExportComponent, modalOptions);
      modalRef.componentInstance.texto = "os lançamentos agrupados por conta";
      modalRef.componentInstance.relatorio = 'livro-razao';
      modalRef.result.then((result: any) => {
        if (result.type === 'pdf') {
          this.exportPDF(result.img);
        } else if (result.type === 'excel') {
          this.exportExcel();
        }
      });
    }
  }

  exportPDF(img){
    const date = moment().format('DD/MM/YY HH:mm').toString();

    let accounts = 'Todas as contas';
    let headerExtra = 0;
    if (this.contasPlano.value != null){
      if (!(this.contasPlano.value.length == this.planosDeConta.length || this.contasPlano.value.length == 0)) {
        let tmp = [];
        this.contasPlano.value.forEach((value) => {
          tmp.push(value.customId);
        });
        accounts = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
        if(accounts.length > 150){
          headerExtra = (Math.trunc((accounts.length - 150)/180)+1)*10;
        }
      }
    }

    let properties = 'Todos os imóveis';
    if (this.property.value != null) {
      if (!(this.property.value.length == this.imoveis.length || this.property.value.length == 0)) {
        let tmp = [];
        this.property.value.forEach((value) => {
          tmp.push(value.propertyCode);
        });
        properties = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
      }
    }

    let bankAccounts = 'Todas as contas';
    if (this.contasMovimento.value != null) {
      if (!(this.contasMovimento.value.length == this.contas.length || this.contasMovimento.value.length == 0)) {
        let tmp = [];
        this.contasMovimento.value.forEach((value) => {
          tmp.push(value.description);
        });
        bankAccounts = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
      }
    }

    let members = 'Todos os produtores';
    if (this.members.value != null) {
      if (!(this.members.value.length == this.socios.length || this.members.value.length == 0)) {
        let tmp = [];
        this.members.value.forEach((value) => {
          tmp.push(value.name);
        });
        if (tmp.length > 1) {
          members = tmp.join(', ');
        } else if (tmp.length == 1) {
          members = `${this.members.value[0].name} (${this.members.value[0].cpfCNPJ})`;
        }
      }
    }

    let deducao: string;
    if (this.deducao === "null" || this.deducao === null)
      deducao = 'Todos os valores';
    else if (this.deducao === "true")
      deducao = 'Dedutíveis';
    else
      deducao = 'Não dedutíveis';

    let period;
    if (this.anual)
      period = this.year;
    else if (this.personalizado)
      period = moment(this.dateRange.startDate).format('DD/MM/YY') + ' - ' + moment(this.dateRange.endDate).format('DD/MM/YY');
    else
      period = this.month.name + ' - ' + this.year;

    let headers = [
      {header: 'Data', dataKey: 'paymentDateFormatted'},
      {header: 'Participante', dataKey: 'participantName'},
      {header: 'Conta Movimento', dataKey: 'movementAccount'},
      {header: 'Imóvel', dataKey: 'propertyName'},
      {header: 'Histórico', dataKey:  'historic'},
      {header: 'Valor', dataKey: 'amountPaid'},
      {header: 'Saldo', dataKey: 'balance'}
    ];

    let group: { [x: string]: any[]; } = {};
    this.lancamentos.forEach((value: any) => {
      let key = value.accountDescription;
      if (!group[key]) {
        group[key] = [];
      }
      group[key].push({
        paymentDateFormatted: value.paymentDateFormatted,
        participantName: value.participantName + " - " + value.participantCpfCnpj,
        movementAccount: value.movementAccount,
        propertyName: value.propertyName,
        historic: value.historic,
        amountPaid: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.amountPaidFormatted.replaceAll(".", "").replace(',', '.')),
        accountDescription: '',
        balance: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.balanceFormatted.replaceAll(".", "").replace(',', '.'))
      });
    });
    let tmp = [];
    for (let key in group) {
      tmp.push({
        accountDescription: key,
        paymentDateFormatted: "",
        participantName: "",
        movementAccount: "",
        propertyName: "",
        historic: "",
        amountPaid: "",
        balance: "",
        depth: 0
      })
      group[key].forEach((value: any) => {
        tmp.push(value);
      });
    }

    let colunas = [
      {dataKey: 'movementAccount', minCellWidth: 3},
      {dataKey: 'propertyName', minCellWidth: 3},
      {dataKey: 'historic', maxCellWidth: 4},
      {dataKey: 'amountPaid', minCellWidth: 2.5, halign: 'center'},
      {dataKey: 'balance', minCellWidth: 2.5, halign: 'center'},
    ];

    this.reportsUtil.exportPDF({
      doc: {
        arquivo: `Livro razao - ${period}`,
        orientacao: 'Landscape',
      },
      cabecalho: {
        titulo: `Livro Razão`,
        data: date,
        periodo: period,
        parametros: {
          "Dedução": deducao,
          "Imóveis": properties,
          "Produtores": members,
          "Contas Movimento": bankAccounts,
          "Contas": accounts,
        },
        image: img,
        alturaExtra: headerExtra,
      },
      tabela: {
        colunas: headers,
        dados: tmp,
        diminuir: true,
        estiloColunas: colunas,
        hierarquico: true,
      },
    });
  }

  exportExcel(){
    const exportProperties: ExcelExportProperties = {
      includeHiddenColumn: true,
      fileName: 'livrorazao' + this.year + '.xlsx',
    };
    this.grid.excelExport(exportProperties);
  }

  changePeriodStart(data) {
    if (data.value != null && this.dateRange.startDate != null && this.dateRange.endDate != null) {
      this.dateRange.startDate = moment.tz(data.value, 'America/Sao_Paulo').format('YYYY-MM-DD');
      //this.tabsTypeFilter();
    }
  }

  changePeriodEnd(data) {
    if (data.value != null && this.dateRange.startDate != null && this.dateRange.endDate != null) {
      this.dateRange.endDate = moment.tz(data.value, 'America/Sao_Paulo').format('YYYY-MM-DD');
    // this.tabsTypeFilter();
    }
  }

  isNumeric(str) {
    return /^\d+$/.test(str);
  }
}

function getYears(startYear) {
  // tslint:disable-next-line:one-variable-per-declaration
  const currentYear = new Date().getFullYear() + 1, years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

class ConsultDate {
  endDate: any;
  startDate: any;
}
