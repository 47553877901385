import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { LivroCaixa } from '../../../../util/livroCaixa';
import { PropertyProvider } from '../../../../providers/company/property/property';
import { PessoaFiscalDTO } from '../../../../model/dto/PessoaFiscalDTO';
import { ImovelDTO } from '../../../../model/dto/ImovelDTO';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BankAccountProvider } from '../../../../providers/company/bank_account/bank_account';
import { ContaBancariaDTO } from '../../../../model/dto/ContaBancariaDTO';
import { Snackbar } from '../../../../util/snackbar';
import { ConsultaDfe } from '../../../../model/ConsultaDfe';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ParticipantProvider } from '../../../../providers/participant/participant';
import { ParticipantDTOResponse } from '../../../../model/dto/ParticipantDTOResponse';
import { AccountProvider } from '../../../../providers/account/account';
import { GrouperDTOResponse } from '../../../../model/dto/GrouperDTOResponse';
import { GrouperProvider } from '../../../../providers/grouper/grouper';
import { CadastroContaBancariaComponent } from '../../minhasPropriedades/cadastro-conta-bancaria/cadastro-conta-bancaria.component';
import { CadastroImovelComponent } from '../../minhasPropriedades/cadastro-imovel/cadastro-imovel.component';
import { CadastroPlanoDeContasComponent } from '../../cadastros/plano-de-contas/cadastro-plano-de-contas/cadastro-plano-de-contas.component';
import { CadastroPessoasComponent } from '../../cadastros/participantes/cadastro-pessoas/cadastro-pessoas.component';
import { CadastroAgrupadoresComponent } from '../../cadastros/agrupadores/cadastro-agrupadores/cadastro-agrupadores.component';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { PostingProvider } from '../../../../providers/bookkeeping/posting/posting';
import { ErrorUtil } from '../../../../util/error';
import { AccountPlanDTOResponse } from '../../../../model/dto/AccountPlanDTOResponse';
import { ReleaseType } from '../../../../model/enum/ReleaseType';
import { DocTypeNumber } from '../../../../model/enum/DocType';
import { MatDialog } from '@angular/material/dialog';
import { DeletarLancamentoComponent } from './deletar-lancamento/deletar-lancamento.component';
import { DocumentProvider } from '../../../../providers/bookkeeping/document/document';
import { ValueValidator } from '../../../../util/customValidators/valueValidator';
import { DeletarDocumentoComponent } from './deletar-documento/deletar-documento.component';
import { DataTrabalhoDialog } from '../data-trabalho-dialog/data-trabalho-dialog.component';
import { MatSelect } from '@angular/material/select';
import { AlertDocDuplicadoComponent } from './alert-doc-duplicado/alert-doc-duplicado.component';
import { HistoricDTOResponse } from '../../../../model/dto/HistoricDTOResponse';
import { HistoricProvider } from '../../../../providers/bookkeeping/historic/historic';
import { CadastroHistoricoComponent } from '../../cadastros/historicos/cadastro-historico/cadastro-historico.component';
import { DateUtil } from "../../../../util/dateUtil";
import { ConvertObjectPipe } from "../../../../util/pipes/ConvertObjectPipe";
import { DfeProvider } from 'src/providers/dfe/dfe';
import { ConsultaPdfXml } from 'src/providers/dfe/consultaPdfXml';
import { DateLockProvider } from 'src/providers/bookkeeping/date-lock/date-lock';
import Swal from 'sweetalert2'
import { DateLock } from 'src/model/dto/bookkeping/DateLock';
import { CheckPermission } from 'src/service/checkPermission';
import { ParticipantPageable } from 'src/model/dto/bookkeping/ParticipantPageable';
import { AdicionarArquivosComponent } from '../../consultor/enviar-arquivos/adicionar-arquivos/adicionar-arquivos.component';
import { ReceiptProvider } from 'src/providers/receipt/receipt';
import { OFXProvider } from 'src/providers/bookkeeping/ofx/ofx';
import {historicoUtil} from "src/util/historico"
import { MatChipInputEvent } from '@angular/material';

@Component({
  host: {
    '(document:keydown)': 'handleKeyboardEvent($event)'
  },
  selector: 'app-cadastrar-lancamento',
  templateUrl: './cadastrar-lancamento.component.html',
  styleUrls: ['./cadastrar-lancamento.component.scss']
})


export class CadastrarLancamentoComponent implements OnInit {

  @Input() public contratoExistente: ConsultaDfe;
  @Input() public lancamentoExistenteID: number;

  @Input() public categoriasCache: AccountPlanDTOResponse[];
  @Input() public imoveisCache: ImovelDTO[];
  @Input() public contasCache: ContaBancariaDTO[];
  // @Input() public participantesCache: ParticipantDTOResponse[];
  @Input() public historicsCache: any[];

  @ViewChild("myinput", {static: false}) myInputField: ElementRef;
  @ViewChild('grouperInput', {static: false}) grouperInput: ElementRef<HTMLInputElement>;
  @ViewChild('receiptInput', {static: false}) receiptInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;
  @ViewChild('singleSelect', {static: true}) singleSelect: MatSelect;
  @ViewChild('selectParticipante', {static: false}) selectParticipante: MatSelect;
  @ViewChild('historicInput', {static: false}) historicInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoHistoric', {static: false}) matAutocompleteHistoric: MatAutocomplete;

  public lancamentoForm: FormGroup;

  public numeroDeParcelas = 1;
  public valorTotal = '0';
  public paymentID: number;

  public carregando: boolean = false;
  public ofxId: number;

  public editar = false;
  public errosPut: boolean[] = [];
  public enviado = false;
  public changeMade = false;
  public documentCarried = false;

  public agrupadores: GrouperDTOResponse[];
  public categorias: Array<AccountPlanDTOResponse> = []; // accountPlan
  public imoveis: ImovelDTO[];
  public contas: ContaBancariaDTO[] = [];  // contasBancárias
  public participantes: ParticipantDTOResponse[];
  public participantLoaded: boolean = false;

  // grouper
  public selectable = true;
  public removable = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public groupers: any = [];
  public receipts: any = [];
  public allGroupers: any[];
  public allReceipts: any[];
  public filteredGroupers: GrouperDTOResponse[];
  public filteredReceipts: any[]

  public filteredReceipt: Observable<any[]>;
  public filteredGrouper: Observable<GrouperDTOResponse[]>;
  // tslint:disable-next-line:variable-name
  public filter_property: ReplaySubject<ImovelDTO[]> = new ReplaySubject<ImovelDTO[]>(1);
  // tslint:disable-next-line:variable-name
  public filter_participant: ReplaySubject<ParticipantDTOResponse[]> = new ReplaySubject<ParticipantDTOResponse[]>(1);
  // tslint:disable-next-line:variable-name
  public filter_bankAccount: ReplaySubject<ContaBancariaDTO[]>[] = [];
  // tslint:disable-next-line:variable-name
  public filter_planAccount: ReplaySubject<AccountPlanDTOResponse[]>[] = [];
  // tslint:disable-next-line:variable-name
  public filter_historic: ReplaySubject<HistoricDTOResponse[]>[] = [];

  public dados: any;
  public conciliacao = false;
  public selected: any;
  public selectedText: string;

  public grouperCtrl = new FormControl();
  public receiptCtrl = new FormControl();
  public propertyFilterCtrl: FormControl = new FormControl();
  public participantFilterCtrl: FormControl = new FormControl();
  separatorKeysCodesHistoric: number[] = [ENTER, COMMA];
  historicCtrl = new FormControl();
  filteredHistorics: Observable<string[]>;
  historics: string[] = [];
  historico: string;
  allHistorics: string[] = ['Pagamento', 'Participante', 'Imovel', 'Lançamento referente', 'dfe'];
  public historicList: HistoricDTOResponse[];
  // tslint:disable-next-line:variable-name
  protected _onDestroy = new Subject<void>();

  public height = 15.75;

  public invalidDescription = false;
  public plannedPaymentId: number;
  public isChanging = false;
  public isCloned = false;
  public locks: DateLock[] = [];
  public participantPage: number = 0;
  public firstFromForm: boolean = false;
  public currentParticipant: ParticipantDTOResponse = new ParticipantDTOResponse();
  public loadingHistoric: boolean = false;
  public loadingAccount: boolean = false;

  public importedFile = null;
  public importedLancamentos = null;
  public importType = null;
  public importModel = null;
  public selectedHistoricId = null;

  public loading : boolean = false;

  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key == 'Escape'){
      this.activeModal.close(this.changeMade);
    }
  }

  constructor(public activeModal: NgbActiveModal,
              private propertyProvider: PropertyProvider,
              private participantProvider: ParticipantProvider,
              private accountProvider: AccountProvider,
              private bankAccountProvider: BankAccountProvider,
              private grouperProvider: GrouperProvider,
              public dateUtil: DateUtil,
              private postingProvider: PostingProvider,
              private documentProvider: DocumentProvider,
              private historicProvider: HistoricProvider,
              private snackBar: Snackbar,
              public errorUtil: ErrorUtil,
              public dialog: MatDialog,
              public historicoUtil: historicoUtil,
              public fb: FormBuilder,
              public snackbar: Snackbar,
              public livroCaixa: LivroCaixa,
              public modalService: NgbModal,
              public dfeProvider: DfeProvider,
              public consultaPdfXml: ConsultaPdfXml,
              public dateLockProvider: DateLockProvider,
              public checkPermission: CheckPermission,
              public receiptProvider: ReceiptProvider,
              public ofxProvider: OFXProvider,
              public adicionarArquivosModalService: NgbModal,) {
  }

  get lancamentos(): FormArray {
    return this.lancamentoForm.get('lancamentos') as FormArray;
  }

  ngOnInit() {
    try {
      // console.log(this.lancamentoExistenteID);
      // console.log(this.lancamentoExistenteID != null);
      this.editar = this.lancamentoExistenteID != null;
      this.selected = {};
      this.initializeDataSelectsForm();
      this.initializeFormConfiguration();
      this.initializeHistoricAutoComplete();
      this.getDateLocks();
      if (this.dados != undefined && this.lancamentos != undefined) {
        // this.changeValues();
        this.conciliacao = true;
      }
    } catch (exception) {
      console.error(exception);
    }
  }

   importedLancamentosToForm(){
    // 1. Informações do documento (Tipo Doc e Participante)
    this.lancamentoForm.get('tipoDocumento').setValue("4");
    this.participantProvider.getParticipantById(this.importedLancamentos[0].participantId).then((response: ParticipantDTOResponse) => {
      let pipe = new ConvertObjectPipe();
      this.currentParticipant = response;
      this.participantes.unshift(pipe.transform(this.currentParticipant, 'participantes'));
      this.filterParticipants();
      this.lancamentoForm.get('pessoa').setValue(this.currentParticipant);
    })
    // 2. Lançamentos (Lancamentos, Valor, Conta, Tipo)
    this.numeroDeParcelas = this.importedLancamentos.length;
    this.onChangeLancamentos(this.numeroDeParcelas);
    this.importedLancamentos.forEach((lancamento, i) => {
      this.lancamentoForm.get('lancamentos').get(i.toString()).get('valor').setValue(lancamento.amountPaid);
      this.filter_planAccount[i].forEach((filter_planAccount) =>{
        filter_planAccount.forEach((planAccount) =>{
          if(planAccount.id == lancamento.bindAccountId){
            this.lancamentoForm.get('lancamentos').get(i.toString()).get('categoria').setValue(planAccount);
            this.lancamentoForm.get('lancamentos').get(i.toString()).get('tipoLancamento').setValue("2");
          }
        })
      });
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initializeHistoricAutoComplete() {
    this.filteredHistorics = this.historicCtrl.valueChanges.pipe(
      startWith(null),
      map((historic: string | null) => historic ? this._filterHistoric(historic) : this.allHistorics.slice()));
  }

  genereteHistoric() {
    if (this.historics.length !== 0) {
      let historic = '';
      this.historics.forEach(option => {
        historic = historic + ' ' + option;
      });
      this.historico = historic;
    }
  }

  selectParticipant(value){
    this.loadingHistoric = true;
    this.loadingAccount = true;
    // console.log("selected participant", value);
    this.lancamentoForm.controls.pessoa.setValue(value);
    // console.log("finding account", value.accountId)
    //se tem conta vinculada ao participante
    if(value.accountId){
      this.accountProvider.getAccountById(value.accountId).then((contaResult : any) =>{
      if(contaResult){
        // console.log("account found", contaResult)
        this.loadingAccount = false;
        this.setConta(contaResult).then((result) =>{
          if(result){
            this.lancamentos.controls.forEach((lancamento, index) =>{
              this.setDefaultTipoLancamento(index);
            });
             if(contaResult.historicId){
              // console.log("finding historic")
              this.historicProvider.getHistoricById(contaResult.historicId).then((historicResult : any) =>{
                if(historicResult){
                  // console.log("historic found", historicResult)
                  let historic = this.formatHistoric({...historicResult});
                  this.lancamentos.controls.forEach((lancamento) =>{
                    lancamento.get('modelo').setValue(((historicResult.model ? historicResult.model.replaceAll('#','') : historic.model)));
                    lancamento.get('descricao').setValue(historic.model);
                  })
                  this.loadingHistoric = false;
                  this.snackbar.openLong("Inseridos plano de conta e histórico associados ao participante", 'success');
                }
                else{
                  this.loadingHistoric = false;
                }
              }).catch((error) =>{
                this.loadingHistoric = false;
              })
            }
            else{

              this.snackbar.openLong("Inserido plano de conta associado ao participante", 'success');
              this.loadingHistoric = false;
            }
          }
        })
      }
      else{
        this.loadingHistoric = false;
        this.loadingAccount = false;
      }
    }).catch((error) =>{
      this.loadingHistoric = false;
      this.loadingAccount = false;
    });
  }
    //se não tem conta vinculada ao participante
    else{
      this.loadingHistoric = false;
      this.loadingAccount = false;
    }

  }

  selectContaResultado(conta, index){
    this.loadingHistoric = true;
    this.accountProvider.getAccountById(conta.id).then((contaResult : any) =>{
      if(contaResult){
        this.setDefaultTipoLancamento(index);
        if(contaResult.historicId){
          // console.log("finding historic")
          this.historicProvider.getHistoricById(contaResult.historicId).then((historicResult : any) =>{
            if(historicResult){
              //console.log("historic found", historicResult)
              //let historic = this.formatHistoric(historicResult);
              //console.log("lancamentos", this.lancamentos)
              let re = new RegExp('#', "g");
              this.lancamentos.controls[index].get('modelo').setValue(historicResult.model.replace(re,''));
              this.loadingHistoric = false;
              this.snackbar.openLong("Selecionado histórico associado a conta resultado", 'success');
            }
            else{
              this.loadingHistoric = false;
            }
          }).catch((error) =>{
            this.loadingHistoric = false;
          })
        }
        else{
          this.loadingHistoric = false;
        }
      }
      else{
        this.loadingHistoric = false;
      }
    }).catch((error) =>{
      this.loadingHistoric = false;
    });
  }

  setConta(result){
    return new Promise((resolve, reject) =>{
      //console.log("account found", result);
      let conta = result;
      //console.log("filter_planAccount", this.filter_planAccount);
      this.filter_planAccount[0].forEach((filter_planAccount) =>{
        filter_planAccount.forEach((planAccount) =>{
          if(planAccount.id == conta.id){
            //console.log("setting plan account", planAccount);
            this.lancamentos.controls.forEach((lancamento) =>{
              lancamento.get('categoria').setValue(planAccount);
            })
          }
        });
      });
      resolve(true);
    })

  }

  formatHistoric(historic){
    const historicSplit = historic.model.split('#');
    let historicString = '';
    historicSplit.forEach(str => {
      if (str.includes('@')) {
        historicString = historicString + ' ' + this.getHistoricTag(str.trim(), 0);
      } else {
        historicString = historicString + ' ' + str.trim();
      }
    });
    historic.model = historicString.trim();
    return historic;
  }

  initializeFormArrayFilter(i) {
    this.filter_bankAccount[i] = new ReplaySubject<any[]>(1);
    this.filter_planAccount[i] = new ReplaySubject<any[]>(1);
    this.filter_historic[i] = new ReplaySubject<any[]>(1);

    this.historicCtrl.valueChanges
      .subscribe(() => {
        this.searchHistoric(i);
      });

    this.lancamentos.controls[i].get('descricao').valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        startWith(this.lancamentos.controls[i].get('descricao').value))
      .subscribe(() => {
        this.filterHistoric(i);
      });

    this.lancamentos.controls[i].get('bankAccount_filter_Ctrl').valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        startWith(this.lancamentos.controls[i].get('bankAccount_filter_Ctrl').value))
      .subscribe(() => {
        this.filterBankAccounts(i);
      });

    this.lancamentos.controls[i].get('planAccount_filter_Ctrl').valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        startWith(this.lancamentos.controls[i].get('planAccount_filter_Ctrl').value))
      .subscribe(() => {
        this.filterPlanAccounts(i);
      });
  }

  filterPlanAccounts(i) {
    if (!this.categorias) {
      return;
    }

    let search = this.lancamentos.controls[i].get('planAccount_filter_Ctrl').value;

    if (!search) {
      this.filter_planAccount[i].next(this.categorias.slice());
    } else {
      // filter the planAccount
      search = search.toLowerCase();
      this.filter_planAccount[i].next(
        this.categorias.filter(value => value.description.toLowerCase().indexOf(search) > -1)
          .sort((a, b) => a.description.indexOf(search) - b.description.indexOf(search))
      );
    }
  }

  filterBankAccounts(i) {
    if (!this.contas) {
      return;
    }
    let search = this.lancamentos.controls[i].get('bankAccount_filter_Ctrl').value;
    if (!search) {
      this.filter_bankAccount[i].next(this.contas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filter_bankAccount[i].next(
      this.contas.filter(value => value.description.toLowerCase().indexOf(search) > -1)
    );
  }

  searchHistoric(i: number){
    if(this.historicCtrl.value == ""){
      this.filter_historic[i].next(this.generateHistoricModels(i).slice());
    } else {
      let result = this.generateHistoricModels(i).filter((historic) => historic.description.toLowerCase().includes(this.historicCtrl.value.toLowerCase()))
      result.length === 0 ? this.filter_historic[i].next([{ id: null, code: null, model: null, description: "Nenhum histórico encontrado" }]) : this.filter_historic[i].next(result)
    }
  }
  setHistoricOption(value){
    this.selectedHistoricId = value.id;
  }

  filterHistoric(i) {
    let search = this.lancamentos.controls[i].get('descricao').value;
    //console.log(this.lancamentos.controls[i])
    //console.log("search",search)
    if (!search) {
      this.filter_historic[i].next(this.generateHistoricModels(i).slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filter_historic[i].next(
      this.generateHistoricModels(i).filter(value => (value.description.toLowerCase().indexOf(search) > -1 ||
        value.code.toLowerCase().indexOf(search) > -1))
    );
  }

  setObjectToEdit(payments) {
    const participantId = this.checkValue(payments[0].doc.participant);

    if(payments[0].doc.hasFileAttached){
      this.documentProvider.getFilesByDocId(payments[0].doc.id).then((result) =>{
        this.receipts = result;
      })
    }

    if (participantId != '') {
      this.participantProvider.getParticipantById(participantId).then((response: ParticipantDTOResponse) => {
        let pipe = new ConvertObjectPipe();
        this.currentParticipant = response;

        this.participantes.unshift(pipe.transform(this.currentParticipant, 'participantes'));
        this.filterParticipants();
      }).then(() => {
        let imovelDTO: ImovelDTO = new ImovelDTO();
      if(payments[0].doc.property == null){
        imovelDTO = null;
      } else {
        this.imoveis.forEach((imovel: ImovelDTO) => {
          if (imovel.propertyCode === payments[0].doc.property.propertyCode) {
            imovelDTO = imovel;
          }
        });
      }

        if(payments[0].doc.hasFileAttached){
          this.documentProvider.getFilesByDocId(payments[0].doc.id).then((result) =>{
            this.receipts = result;
          })

        }


        const documento = {
          imovel: imovelDTO,
          documento: payments[0].doc.number,
          pessoa: this.currentParticipant,
          tipoDocumento: DocTypeNumber[payments[0].doc.docType].toString(),
          agrupador: payments[0].grouper
        };

        this.groupers = payments[0].grouper;
        this.firstFromForm = true;
        this.lancamentoForm.patchValue(documento);
        this.setObjectLancamentosToEdit(payments);
      });
    } else {
      let imovelDTO: ImovelDTO = new ImovelDTO();
    if(payments[0].doc.property == null){
      imovelDTO = null;
    } else {
      this.imoveis.forEach((imovel: ImovelDTO) => {
        if (imovel.propertyCode === payments[0].doc.property.propertyCode) {
          imovelDTO = imovel;
        }
      });
    }

      const documento = {
        imovel: imovelDTO,
        documento: payments[0].doc.number,
        pessoa: this.currentParticipant,
        tipoDocumento: DocTypeNumber[payments[0].doc.docType].toString(),
        agrupador: payments[0].grouper
      };

      this.groupers = payments[0].grouper;
      this.firstFromForm = false;
      this.lancamentoForm.patchValue(documento);
      this.setObjectLancamentosToEdit(payments);
    //console.log( "PERDIDO", this.lancamentoForm)
    }
  }

  checkValue(value) {
    if (value == null) {
      return '';
    } else {
      return value.id;
    }
  }

  openFileModal() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
        const modalRef = this.adicionarArquivosModalService.open(AdicionarArquivosComponent, modalOptions);
        modalRef.result.then((result: any) => {
          if (result !== 'Cancelou') {
            this.receiptProvider.getReceiptsByCompanyId().then((receipts: any[]) => {
              this.allReceipts = receipts;
              this.allReceipts.forEach((receipt) =>{
                receipt.formatedDate = moment(receipt.date).locale('pt-BR').format('DD/MM/YYYY')
              })
              this.filteredReceipts = this.allReceipts;
              this.receiptCtrl.setValue('');
              if(result.lenght != 0){
                result.forEach((receipt) =>{this.receipts.push(receipt)})
              }

            });
          }
        });
    } catch (error) {
        console.error('', error);
    }
  }

  checkValueParticipant(value) {
    if (value == null) {
      return '';
    } else {
      return value.cpfCNPJParticipant;
    }
  }

  checkDocumentIsCarried() {
    this.documentCarried = false;
    const formValue = this.lancamentoForm.value;
    // fix
    if (formValue.pessoa != null) {
      if (formValue.pessoa.id !== undefined && formValue.documento !== null && formValue.documento.trim() !== '' && formValue.pessoa !== '' && !this.editar) {
        const objectDocument = {
          companyId: localStorage.getItem('idEmpresa'),
          number: formValue.documento,
          participantId: formValue.pessoa.id
        };
        this.documentProvider.checkIfDocumentIsCarried(objectDocument).then((isCarried: boolean) => {
          this.documentCarried = isCarried;
        }).catch((result: HttpErrorResponse) => {
          this.snackBar.open('Não foi possível verificar se já existe escrituração. ' +
            this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookepping'), 'erro');
        });
      }
    }
    return this.documentCarried;
  }

  checkDocumentIsCarriedEvent(event: any) {
    this.documentCarried = false;
    const formValue = this.lancamentoForm.value;
    // fix
    if (formValue.pessoa != null) {
      if (formValue.pessoa.id !== undefined && event.target.value !== null && event.target.value.trim() !== '' && formValue.pessoa !== '' && !this.editar) {
        const objectDocument = {
          companyId: localStorage.getItem('idEmpresa'),
          number: event.target.value,
          participantId: formValue.pessoa.id
        };
        this.documentProvider.checkIfDocumentIsCarried(objectDocument).then((isCarried: boolean) => {
          this.documentCarried = isCarried;
        }).catch((result: HttpErrorResponse) => {
          this.snackBar.open('Não foi possível verificar se já existe escrituração. ' +
            this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookepping'), 'erro');
        });
      }
    }
    return this.documentCarried;
  }

  setObjectLancamentosToEdit(payments: PaymentEdit[]) {
    this.lancamentos.clear();
    this.paymentID = payments[0].payment.id;
    payments = payments.sort((a, b) => a.id - b.id);
    payments.forEach((payment, i) => {
      //console.log(payment, "DAMMMM SHAWTY")
      let account: AccountPlanDTOResponse = new AccountPlanDTOResponse();
      this.categorias.forEach((accountPlanDTOResponse: AccountPlanDTOResponse) => {
        if (accountPlanDTOResponse.id === payment.account.id) {
          account = accountPlanDTOResponse;
        }
      });
      let bankAccount: ContaBancariaDTO = new ContaBancariaDTO();
      this.contas.forEach((bankAccountResp: ContaBancariaDTO) => {
        if (bankAccountResp.id === this.checkValue(payment.bankAccount)) {
          bankAccount = bankAccountResp;
        }
      });
      this.selected = bankAccount;
      const controls = this.lancamentoForm.controls.lancamentos as FormArray;

      const formGroup = this.fb.group({
        id: payment.id,
        dataPagamento: payment.paymentDate,
        valor: payment.amountPaid,
        descricao: payment.historic,
        contabancaria: bankAccount,
        categoria: account,
        planPaymentId: payment.planPaymentId,
        bankAccount_filter_Ctrl: [],
        planAccount_filter_Ctrl: [],
        tipoLancamento: ReleaseType[payment.postingReleaseType].toString(),
        ofxId: payment.ofxId
      });
      //console.log("meuy senhor",payment.modelo)
     // const model =

          payment.historicoUtil = new historicoUtil
          //console.log("meuy senhor2",payment)

         // payment.historicoUtil.historics = <String>payment.modelo
         //console.log("meuy senhor3",payment)

         //console.log("fffff",payment.historicoUtil.historics )
        //console.log()
        if(payment.modelo){
            let re = new RegExp('#', "g");
        formGroup.addControl('modelo', this.fb.control(payment.modelo.replace(re,'')));
        payment.historicoUtil.historics = payment.modelo.replace(re, '').split(' ');
        }else{
          formGroup.addControl('modelo', this.fb.control(payment.historic));
          payment.historicoUtil.historics = payment.historic.split(' ');
        }

        formGroup.addControl('provider', this.fb.control(payment.historicoUtil));
        this. updateDescFromModel(i)

       // formGroup.controls.descricao.setValue(this.generateHistoricModelsNewHistoric(i,payment.historicoUtil.historics)[0])


      if (payment.ofxId){
        this.dados = formGroup.value;
        formGroup.get('dataPagamento').disable();
        formGroup.get('valor').disable();
        formGroup.get('descricao').disable();
        formGroup.get('contabancaria').disable();
        formGroup.get('categoria').disable();
        formGroup.get('tipoLancamento').disable();
      }

      controls.push(formGroup);
      //console.log("",formGroup)
      this.initializeFormArrayFilter(controls.length - 1);
    });
  }
  updateDescFromModel(i){
    //console.log("SIUUUUUU",this.lancamentos.controls[i])
    let teste: any = this.lancamentos.controls[i];
    if(teste != undefined){
      teste.controls.descricao.setValue(this.generateHistoricModelsNewHistoric(i, teste.value.provider.historics)[0])
    }
  }
  updateAllDescFromModel(){
    let teste: any = this.lancamentos.controls;
    teste.forEach((element,i )=> {
      if(element.value.provider){
        element.controls.descricao.setValue(this.generateHistoricModelsNewHistoric(i, element.value.provider.historics)[0])
      }
    })
  }
  addOnBlur(event: FocusEvent, historicoUtil) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent: MatChipInputEvent = {input: this.historicInput.nativeElement, value : this.historicInput.nativeElement.value};
      historicoUtil.addHistoric(matChipEvent);
    }
  }

  selectedHistoric(event, historicoUtil): void {
    historicoUtil.historics.push(event.option.viewValue);
     this.historicInput.nativeElement.value = '';
     historicoUtil.historicCtrl.setValue(null);
   }
  initializeSearchSelect() {
    this.filter_property.next(this.imoveis.slice());
    this.propertyFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProperties();
      });

    this.filter_participant.next(this.participantes.slice());
    this.participantFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterParticipants();
      });

    this.filteredGrouper = this.grouperCtrl.valueChanges.pipe(
      startWith(''),
      map((fruit: string | null) => fruit ? this._filterG(fruit) : this.allGroupers.slice()));

      this.filteredReceipt = this.receiptCtrl.valueChanges.pipe(
        startWith(''),
        map((fruit: string | null) => fruit ? this._filterR(fruit) : this.allReceipts.slice()));
  }

  initializeDataWithCache() {
    this.contas = this.contasCache;
    this.imoveis = this.imoveisCache;
    this.categorias = this.categoriasCache;
    // this.participantes = this.participantesCache;
    localStorage.setItem('historics', JSON.stringify(this.sortHistorics(this.historicsCache)));

    let pipe = new ConvertObjectPipe();

    this.participantProvider.getParticipantsByPage('', this.participantPage).then((participantes: ParticipantPageable) => {
      this.participantes = pipe.transform(participantes.content, 'participantes');
      this.participantPage++;
      this.grouperProvider.getAllGroupers().then((groupers: GrouperDTOResponse[]) => {
        this.receiptProvider.getReceiptsByCompanyId().then((receipts: any[]) => {
          if (this.editar) {
            this.postingProvider.getAllByDoc(this.lancamentoExistenteID).then((payments) => {
              //console.log("payment", payments);
              this.plannedPaymentId = payments[0].planPaymentId;
              this.historicoUtil.checkHistoricOrObject(payments)

              this.setObjectToEdit(payments);
            });
          } else {
            this.initializeFormArrayFilter(0);
          }
          this.allGroupers = groupers;
          this.agrupadores = groupers;
          this.filteredGroupers = this.agrupadores;

          this.allReceipts = receipts;
          this.allReceipts.forEach((receipt) =>{
            receipt.formatedDate = moment(receipt.date).locale('pt-BR').format('DD/MM/YYYY')
          })
          this.filteredReceipts = this.allReceipts;
          this.initializeSearchSelect();
        });

      });
    });
  }

  initializeDataWithoutCache() {
    this.loading = true;
    let pipe = new ConvertObjectPipe();
    this.bankAccountProvider.getAllBanksAccounts().then((contas: any[]) => {
      this.contas = pipe.transform(contas, 'contasBancarias');
      this.participantProvider.getParticipantsByPage('', this.participantPage).then((participantes: ParticipantPageable) => {
        this.participantes = pipe.transform(participantes.content, 'participantes');
        this.participantPage++;
        this.propertyProvider.getAllProperty().then((imoveis: any[]) => {
          this.imoveis = pipe.transform(imoveis, 'imoveis');
          this.historicProvider.getAllHistorics().then((historicos: HistoricDTOResponse[]) => {
            localStorage.setItem('historics', JSON.stringify(this.sortHistorics(pipe.transform(historicos, 'contasBancarias'))));
            this.accountProvider.getAllAccountsLeafs().then((categorias: AccountPlanDTOResponse[]) => {
              this.categorias = pipe.transform(categorias, 'categoria');
              this.grouperProvider.getAllGroupers().then((groupers: GrouperDTOResponse[]) => {
                this.receiptProvider.getReceiptsByCompanyId().then((receipts: any[]) => {
                  if (this.editar) {
                    this.postingProvider.getAllByDoc(this.lancamentoExistenteID).then((payments: PaymentEdit[]) => {
                      this.plannedPaymentId = payments[0].planPaymentId;
                      this.historicoUtil.checkHistoricOrObject(payments)
                      this.setObjectToEdit(payments);
                    });
                  } else {
                    this.initializeFormArrayFilter(0);
                  }
                  this.allGroupers = groupers;
                  this.agrupadores = groupers;
                  this.filteredGroupers = this.agrupadores;

                  this.allReceipts = receipts;
                  this.allReceipts.forEach((receipt) =>{
                    receipt.formatedDate = moment(receipt.date).locale('pt-BR').format('DD/MM/YYYY')
                  })
                  this.filteredReceipts = this.allReceipts;

                  this.initializeSearchSelect();

                  if (this.contas && this.imoveis) this.setValuesDefault();
                  if (this.conciliacao) this.changeValues();

                  if(this.importedLancamentos != null){
                    this.importedLancamentosToForm();
                  }
                  this.loading = false;
                });
              });
            });
          });
        });
      });
    });
  }

  setValuesDefault() {
    this.lancamentoForm.get('imovel').setValue(this.imoveis.find((imovel) => imovel.default));
    let conta = this.contas.find((conta) => conta.default);
    this.lancamentos.controls.forEach((formGroup: FormGroup) => {
      formGroup.get('contabancaria').setValue(conta);
    });
  }

  initializeDataSelectsForm() {
    // console.warn(this.categoriasCache);

    if (this.categoriasCache === undefined) {
      this.initializeDataWithoutCache();
    } else {
      this.initializeDataWithCache();
    }
  }

  sortHistorics(historicos) {
    return historicos.sort((a, b) => {
      if (a.code > b.code) {
        return 1;
      } else if (a.code < b.code) {
        return -1;
      } else {
        return 0;
      }
    })
  }

  initializeFormConfiguration() {
    this.lancamentoForm = this.fb.group({
      imovel: [this.imoveis && this.imoveis.length > 0 ? this.imoveis.find(obj => obj.default) : '', Validators.compose([Validators.required])],
      documento: [''],
      pessoa: [undefined],
      tipoDocumento: ['', Validators.required],
      agrupador: [''],
      lancamentos: this.initializeLancamentos()
    });

    if (this.livroCaixa.getTipo() === 1) {
      this.lancamentoForm.controls.pessoa.setValidators(Validators.compose([Validators.required]));
    } else {
      this.lancamentoForm.controls.tipoDocumento.setValue('5');
    }

    this.lancamentoForm.controls.agrupador.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGrouper(value))
      ).subscribe(ret => {
        this.filteredGroupers = ret;
      });

  }

  initializeLancamentos() {
   let provider = new historicoUtil

    return this.fb.array([this.fb.group({
      id: [''],
      bankAccount_filter_Ctrl: [],
      planAccount_filter_Ctrl: [],
      planPaymentId: [],
      dataPagamento: [this.generateDateLancamento(0), Validators.required],
      valor: ['0', Validators.compose([Validators.required, ValueValidator])],
      descricao: ['', Validators.compose([Validators.required, Validators.pattern(/^(?=[^A-Za-zÀ-ý]*[A-Za-zÀ-ý])/g)])],
      contabancaria: this.livroCaixa.getTipo() == 1 ?
        [this.contas && this.contas.length > 0 ? this.contas.find(obj => obj.default) : '', Validators.compose([Validators.required])] :
        [this.contas && this.contas.length > 0 ? this.contas.find(obj => obj.default) : ''],
      categoria: ['', Validators.compose([Validators.required])],
      tipoLancamento: ['', Validators.required],
      provider:  [provider],
      modelo: ['']
    })]);
  }

  removeGrouper(grouper, indx): void {
    this.groupers.splice(indx, 1);
  }

  removeReceipt(indx): void {
    this.receipts.splice(indx, 1);
  }

  selectedGrouper(event: MatAutocompleteSelectedEvent): void {
    if(event.option.value!=0){
      let eventInsert;
      if (event.option === undefined) {
        eventInsert = event;
      } else {
        eventInsert = event.option.value;
      }

      let exist = false;
      this.groupers.forEach((grouper, index: number) => {
        if (grouper.id === eventInsert.id) {
          exist = true;
          this.snackBar.open('Agrupador já selecionado!', 'atencao');
        }
        if (!exist && this.groupers.length === index + 1) {
          this.groupers.push(eventInsert);
          this.grouperInput.nativeElement.value = '';
          this.grouperCtrl.setValue(null);
        }
      });
      if (this.groupers.length === 0) {
        this.groupers.push(eventInsert);
        this.grouperInput.nativeElement.value = '';
        this.grouperCtrl.setValue(null);
      }
    }
  }

  selectedReceipt(event: MatAutocompleteSelectedEvent){
    if(event.option.value!=0){
      let eventInsert;
      if (event.option === undefined) {
        eventInsert = event;
      } else {
        eventInsert = event.option.value;
      }

      let exist = false;
      this.receipts.forEach((receipt, index: number) => {
        if (receipt.id === eventInsert.id) {
          exist = true;
          this.snackBar.open('Arquivo já selecionado!', 'atencao');
        }
        if (!exist && this.receipts.length === index + 1) {
          this.receipts.push(eventInsert);
          this.receiptInput.nativeElement.value = '';
          this.receiptCtrl.setValue(null);
        }
      });
      if (this.receipts.length === 0) {
        this.receipts.push(eventInsert);
        this.receiptInput.nativeElement.value = '';
        this.receiptCtrl.setValue(null);
      }
    }
  }

  addNewLancamento() {
    const controls = this.lancamentoForm.controls.lancamentos as FormArray;
    const formGroup = this.fb.group({
      id: [''],
      bankAccount_filter_Ctrl: [],
      planAccount_filter_Ctrl: [],
      planPaymentId: [],
      dataPagamento: [this.generateDateLancamento(this.lancamentos.controls.length), Validators.required],
      valor: ['0', Validators.compose([Validators.required, ValueValidator])],
      descricao: ['', Validators.required],
      contabancaria: this.livroCaixa.getTipo() == 1 ?
        [this.contas && this.contas.length > 0 ? this.contas.find(obj => obj.default) : '', Validators.compose([Validators.required])] :
        [this.contas && this.contas.length > 0 ? this.contas.find(obj => obj.default) : ''],
      categoria: ['', Validators.compose([Validators.required])],
      tipoLancamento: ['', Validators.required],
       provider:  [new historicoUtil],
       modelo: ['']
    });

    controls.push(formGroup);

    this.initializeFormArrayFilter(controls.length - 1);
  }

  removeItem(i: number, lancamento) {
    if (lancamento.value.id === '') {
      const controls = this.lancamentoForm.controls.lancamentos as FormArray;
      controls.removeAt(i);
      this.numeroDeParcelas--;
    } else {
      if (this.isLockedAt(lancamento.value)) {
        Swal.fire({
          title: 'Atenção!',
          text: 'Este lançamento está cadastrado dentro de um bloqueio de período. ' +
            (this.checkPermission.isAccountantAdmin() ?
              'Remova o bloqueio para poder remover um lançamento nesta data.' :
              'Contate um administrador para que o bloqueio seja removido.'),
          icon: 'error'
        });
      } else {
        this.deleteLancamento(lancamento.value, i);
      }
    }
  }

  deleteLancamento(row: any, i: number) {
    let existingPosting = 0;

    try{
      if (this.dados.id === row.id) {
        row = this.dados;
      }
    }catch{
    }

    this.lancamentos.controls.forEach((control) => {
      if (control.value.id !== '') {
        existingPosting++;
      }
    });
    if (existingPosting > 1) {
      this.deleteLancamentoModal(row, i);
    } else {
      this.snackBar.openLong('Você precisa excluir a escrituração' +
        ' para remover esse lançamento ou editar com novos lançamentos.', 'atencao');
    }
  }

  deleteLancamentoModal(row, i) {
    if (this.editar) {
      this.numeroDeParcelas = this.lancamentos.length;
    }
    const dialogRef = this.dialog.open(DeletarLancamentoComponent, {
      width: '350px',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancelou' && result !== undefined) {
        const controls = this.lancamentoForm.controls.lancamentos as FormArray;
        controls.removeAt(i);
        this.numeroDeParcelas--;
        this.changeMade = true;
        if (this.numeroDeParcelas === 0) {
          this.activeModal.dismiss(this.changeMade);
        }
      }
    });
  }

  _filterGrouper(value: any): GrouperDTOResponse[] {
    if (value !== null && value.type !== undefined) {
      const name = value.name ? value.name : value;
      return value ? this.agrupadores.filter(s => s.name.toLowerCase().indexOf(name.toLowerCase()) > -1)
        : this.agrupadores;
    } else {
      return this.agrupadores;
    }
  }

  onChangeLancamentos(e) {  // controle do número lançamentos, caso for menor remove, maior adiciona um novo
    const numeroDeParcelas = e || 0;
    if (e > this.lancamentos.length) {
      while (this.lancamentos.length < numeroDeParcelas) {
        this.addNewLancamento();
      }
    } else {
      while (this.lancamentos.length > numeroDeParcelas) {
        this.removeItem(this.lancamentos.length - 1, this.lancamentos.controls[this.lancamentos.length - 1]);
      }
    }
  }

  setDataAnotherLancamentos() {  // replica as informações do primeiro lançamento para os demais
    let controlPrimary;
    this.lancamentos.controls.forEach((control: FormGroup, index: number) => {
      if (index !== 0) {
        control.controls.valor.setValue(controlPrimary.controls.valor.value);
        control.controls.descricao.setValue(controlPrimary.controls.descricao.value);
        control.controls.contabancaria.setValue(controlPrimary.controls.contabancaria.value);
        control.controls.tipoLancamento.setValue(controlPrimary.controls.tipoLancamento.value);
        control.controls.categoria.setValue(controlPrimary.controls.categoria.value);
        control.controls.modelo.setValue(controlPrimary.controls.modelo.value);
      } else {
        controlPrimary = control;
      }
    });
  }

  setDataContaMovimento(){
    let controlPrimary;
    this.lancamentos.controls.forEach((control: FormGroup, index: number) => {
      if (index !== 0) {
        control.controls.contabancaria.setValue(controlPrimary.controls.contabancaria.value);
      } else {
        controlPrimary = control;
      }
    });
  }

  setDataHistorico(){
    let controlPrimary;
    this.historicProvider.getHistoricById(this.selectedHistoricId).then((historicResult : any) =>{
      if(historicResult){
        const historicSplit = historicResult.model.split('#');
        this.lancamentos.controls.forEach((control: FormGroup, index: number) => {
          let historicString = '';
          historicSplit.forEach(str => {
            if (str.includes('@')) {
              historicString = historicString + ' ' + this.getHistoricTag(str.trim(), index);
            } else {
              historicString = historicString + ' ' + str.trim();
            }
          });
          if (index !== 0) {
            control.controls.descricao.setValue(historicString);
          } else {
            controlPrimary = control;
          }
        });
      }
      else{
        this.loadingHistoric = false;
      }
    }).catch((error) =>{
      this.loadingHistoric = false;
    })
  }

  generateDateLancamento(condRecebimento: number) {
    if (condRecebimento === 0) {
      return new Date(moment(localStorage.getItem('workDate')).format('YYYY/MM/DD'));
    } else {
      return new Date(moment(moment(this.lancamentoForm.value.lancamentos[condRecebimento - 1].dataPagamento).format()).format());
    }
  }

  newOptionSelect(type: string, index) {
    switch (type) {
      case 'account':
        this.openModalNewOption(CadastroContaBancariaComponent, this.contas, 'contabancaria', index);
        break;
      case 'property':
        this.openModalNewOption(CadastroImovelComponent, this.imoveis, 'imovel', index);
        break;
      case 'accountPlan':
        this.openModalNewOption(CadastroPlanoDeContasComponent, this.categorias, 'categoria', index);
        break;
      case 'participant':
        this.openModalNewOption(CadastroPessoasComponent, this.participantes, 'pessoa', index);
        break;
      case 'grouper':
        this.openModalNewOption(CadastroAgrupadoresComponent, this.agrupadores, 'agrupador', index);
        break;
      case 'historic':
        this.openModalNewOption(CadastroHistoricoComponent, this.agrupadores, 'historico', index);
        break;
      default:
        break;
    }
  }

  openModalNewOption(modalName, list, filtered, index) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(modalName, modalOptions);
    modalRef.componentInstance.participantes = this.participantes;
    modalRef.result.then((result) => {
      let pipe = new ConvertObjectPipe();
      if (filtered === 'contabancaria') {
        list.push(pipe.transform(result, 'contasBancarias'));
        this.initializeFormArrayFilter(index);
        this.lancamentos.controls[index].get(filtered).setValue(result);
      } else if (filtered === 'imovel') {
        list.push(pipe.transform(result, 'imoveis'));
        this.initializeSearchSelect();
        this.lancamentoForm.get(filtered).setValue(result);
      } else if (filtered === 'pessoa') {
        list.push(pipe.transform(result, 'participantes'));
        this.initializeSearchSelect();
        this.lancamentoForm.get(filtered).setValue(result);
      } else if (filtered === 'historico') {
        const historics = JSON.parse(localStorage.getItem('historics'));
        historics.push(result);
        localStorage.setItem('historics', JSON.stringify(historics));
        this.generateHistoricModels(index);
        this.lancamentoForm.get(filtered).setValue(result);
      } else if (filtered === 'agrupador') {
        list.push(result);
        this.grouperProvider.getAllGroupers().then((groupers: GrouperDTOResponse[]) => {
          this.allGroupers = groupers;
          this.groupers.push(result);
          this.filteredGroupers = this.allGroupers;
          this.grouperCtrl.setValue('');
          this.initializeSearchSelect();
        });
      } else {
        list.push(result);
        this.initializeSearchSelect();
        this.lancamentoForm.get(filtered).setValue(result);
      }
    }, () => {

    });
  }
  teste($event, provider,i){
    //console.log("holy molly",$event)
    provider.historics = $event.split(' ')
    //console.log(provider.historics )
   this. updateDescFromModel(i)

  }

  setObjectToImportedPost(){ // transforma o objeto para envio de Documento de integrações
    const formData = this.lancamentoForm.getRawValue();
    const lancamentos = formData.lancamentos;

    const payment = new ImportedPayment();

    const doc = new ImportedDoc();
    doc.number = formData.documento;
    doc.docType = DocTypeNumber[formData.tipoDocumento];
    doc.propertyCode = formData.imovel.propertyCode;
    doc.participantId = formData.pessoa != null ? formData.pessoa.id : null;
    doc.receiptFileIds = []
    this.receipts.forEach((receipt) =>{
      doc.receiptFileIds.push(receipt.id)
    })

    payment.grouperIds = []
    this.groupers.forEach((grouper) =>{
      payment.grouperIds.push(grouper.id)
    })

    doc.postings = [];
    lancamentos.forEach((lancamento) => {
      doc.postings.push(this.setLancamentoToImportedPosting(lancamento));
    });

    payment.doc = doc;
    payment.payrollImportType = this.importType;
    return payment;
  }

  setLancamentoToImportedPosting(lancamento) {
    const posting = new ImportedPosting();
    posting.amountPaid = lancamento.valor;
    posting.historic = lancamento.descricao;
    posting.paymentDate = this.dateUtil.removeTimeZone(lancamento.dataPagamento);
    posting.accountPlanId = lancamento.categoria.id;
    posting.postingReleaseType = lancamento.tipoLancamento;
    posting.bankAccountId = lancamento.contabancaria.id;
    return posting;
  }

  setObjectToPost() {  // transforma o objeto para envio de Documento com lançamentos em lote

    const formData = this.lancamentoForm.getRawValue();
    const lancamentos = formData.lancamentos;

    const documento = new Payment();
    documento.companyId = +localStorage.getItem('idEmpresa');
    documento.proofDoc = null;
    documento.docs = [];

    const docs = new Doc();
    docs.companyId = documento.companyId;
    docs.participantId = formData.pessoa != null ? formData.pessoa.id : null;
    docs.number = formData.documento;
    docs.propertyCode = formData.imovel.propertyCode;
    docs.docType = DocTypeNumber[formData.tipoDocumento];
    docs.postings = [];
    docs.receiptFileIds = []
    this.receipts.forEach((receipt) =>{
      docs.receiptFileIds.push(receipt.id)
    })


    if (docs.docType === undefined || docs.docType === '') {
      docs.docType = 'OTHERS';
    }

    lancamentos.forEach((lancamento) => {
      docs.postings.push(this.convertLancamentosToPostAndPut(lancamento, docs.companyId, true));
    });

    documento.docs.push(docs);

    return documento;

  }

  convertLancamentosToPostAndPut(lancamento, companyID, newPosting) {  // converte os lançamentos para inserção ou edição
    //console.log('OIEEEE',lancamento, companyID, newPosting)
    const posting = new Posting();

    if (lancamento.id !== '') {      // caso o lançamento já exista e sofra alguma alteração
      posting.id = lancamento.id;
      posting.planPaymentId = lancamento.planPaymentId;
    }

    if (!newPosting) { // vincular novos lançamentos ao documento e ao pagamento
      posting.docId = +this.lancamentoExistenteID;
      posting.paymentId = this.paymentID;
    }

    posting.ofxId = lancamento.ofxId;
    if (this.conciliacao) {
      posting.ofxId = this.dados.ofxId;
    }

    posting.amountPaid = lancamento.valor.toFixed(2);
    if(lancamento.provider){
      posting.historic = JSON.stringify({
        historico: lancamento.descricao,
        modelo: lancamento.provider.genereteHistoric()
      })
    }else {
    posting.historic = lancamento.descricao;

    }

    posting.paymentDate = this.dateUtil.removeTimeZone(lancamento.dataPagamento);
    posting.companyId = companyID;
    posting.accountsChartId = lancamento.categoria.id;
    posting.duplicateId = null;
    posting.postingReleaseType = ReleaseType[lancamento.tipoLancamento];
    if (lancamento.contabancaria !== undefined && lancamento.contabancaria !== null) {
      posting.bankAccountId = lancamento.contabancaria.id;
    }
    posting.isDigital = (this.livroCaixa.getTipo() === 1);
    posting.groupersIds = [];

    this.groupers.forEach((grouper) => {
      posting.groupersIds.push(grouper.id);
    });

    return posting;

  }

  setObjectToPostAndPut(document, button) {
    // EDIÇÃO: Verifica quais lançamentos devem ser inseridos e quais já existiam e foram alterados
    const lancamentosToPut = [];
    const lancamentosToPost = [];

    document.lancamentos.forEach((lancamento) => {
      // lançamentos sem ID ainda não existiam e portanto serão inseridos (POST).
      // lançamentos com ID existiam e portanto serão modificados (PUT).

      if (lancamento.ofxId != null) return;

      if (lancamento.id !== '') {
        lancamentosToPut.push(this.convertLancamentosToPostAndPut(lancamento,
          +localStorage.getItem('idEmpresa'), true));
      } else {
        lancamentosToPost.push(this.convertLancamentosToPostAndPut(lancamento,
          +localStorage.getItem('idEmpresa'), false));
      }
    });

    if (lancamentosToPost.length > 0) {  // verifica se há novos lançamentos
      this.postLancamentosDocument(lancamentosToPost, button);
    }

    this.putLancamentosDocument(lancamentosToPut, button);
  }

  setDocumentToPut() {
    const formData = this.lancamentoForm.getRawValue();
    const docs = new Doc();
    docs.id = this.lancamentoExistenteID;
    docs.companyId = +localStorage.getItem('idEmpresa');
    docs.participantId = formData.pessoa != null ? formData.pessoa.id : null;
    docs.number = formData.documento;
    docs.propertyCode = formData.imovel.propertyCode;
    docs.docType = formData.tipoDocumento;
    docs.receiptFileIds = []
    this.receipts.forEach((receipt) =>{
      docs.receiptFileIds.push(receipt.id)
    })

    //console.log("AAAAAA", docs);

    return docs;
  }

  putDocument(button) {
    this.snackBar.openLong('Etapa 1 de 3 - Editando documento atual...', 'atencao');
    this.documentProvider.putDocument(this.setDocumentToPut()).then(() => {
      this.snackBar.openLong('Etapa 1 de 3 - Documento editado com sucesso!', 'success');
      this.errosPut.push(false);
      this.setObjectToPostAndPut(this.lancamentoForm.getRawValue(), button);
    }).catch((result: HttpErrorResponse) => {
      console.error(result);
      button.disabled = false;
      this.errosPut.push(true);
      this.snackBar.openLong('Erro etapa 1 - Não foi possível editar o documento atual! ' +
        this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping'), 'erro');
    });
  }

  putLancamentosDocument(document, button) {
    this.snackBar.openLong('Etapa 2 de 3 - Editando lançamentos atuais...', 'atencao');

    this.lancamentos.controls.forEach((lancamento) => {
      if(lancamento.value.ofxId != null){
        this.activeModal.close(true);
        return
      }
    })

    if (document.length === 0) {
      this.snackBar.openLong('Não há lançamentos para edição', 'atencao');
      this.activeModal.close();
      return;
    }

    this.postingProvider.putPosting(document).then(() => {
      this.snackBar.openLong('Etapa 2 - Lançamento(s) editado(s) com sucesso!', 'success');
      this.errosPut.push(false);
      this.checkErrorEdit();
    }).catch((result: HttpErrorResponse) => {
      this.errosPut.push(true);
      button.disabled = false;
      this.checkErrorEdit();
      this.snackBar.openLong('Erro etapa 2 - Não foi possível editar os lançamentos atuais! ' +
        this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping'), 'erro');
    });
  }

  postLancamentosDocument(document, button) {
    this.snackBar.openLong('Etapa 3 de 3 - Inserindo novos lançamentos...', 'atencao');
    this.postingProvider.postPosting(document).then(() => {
      this.snackBar.openLong('Etapa 3 - Novo(s) lançamento(s) escriturado(s) com sucesso!', 'success');
      this.errosPut.push(false);
    }).catch((result: HttpErrorResponse) => {
      console.error(result);
      button.disabled = false;
      this.errosPut.push(true);
      this.snackBar.openLong('Erro etapa 3 - Não foi possível inserir os novos lançamentos! ' +
        this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping'), 'erro');
    });
  }

  checkErrorEdit() {
    let erro: boolean;
    this.errosPut.forEach((result) => {
      if (result) {
        erro = true;
      }
    });
    if (!erro) {
      this.changeMade = true;
      this.activeModal.close(this.changeMade);
      this.snackbar.openLong('Documento editado com sucesso! Todas etapas foram concluídas!', 'success');
    } else {
      this.snackbar.openLong('Não foi possível completar todas as etapas.', 'erro');
    }
  }

  postImportedDocument(button) {
    if (this.importModel == 'RESUMO') {
      this.postingProvider.postImportedResumePostingBatch(this.setObjectToImportedPost()).then((result) => {
        this.snackBar.openLong('Lançamentos importados com sucesso!', 'success');
        this.myInputField.nativeElement.focus();
        window.scroll({
          top: 0
        });
        this.changeMade = true;
        if (button.id !== 'insertButtonTwo') {
          for (let i = 0; i < this.lancamentos.controls.length; i++) {
            // this.removeItem(i, this.lancamentos.controls[i]);
            button.disabled = false;
          }
          this.resetForm();
          this.enviado = false;
          button.disabled = false;
          this.checkDocumentIsCarried();
        } else {
          button.disabled = false;
          const formValue2 = this.lancamentoForm.value
          if (formValue2.pessoa != null) {
            if (formValue2.documento !== '' && formValue2.pessoa.id !== undefined) {
              this.checkDocumentIsCarried();
            }
          }
        }
      }).catch((result: HttpErrorResponse) => {
        console.error(result);
        button.disabled = false;
        this.snackBar.openLong('Erro!' +
          this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping'), 'erro');
      });
    } else {
    }
  }

  postNewDocument(button) {
    this.postingProvider.postPostingBatch(this.setObjectToPost()).then((result) => {
      //console.log("result",result)
      this.snackBar.openLong('Documento n° '+result[0].doc.number+' escriturado com sucesso!', 'success');
      this.changeMade = true;
      this.myInputField.nativeElement.focus();
      window.scroll({
        top: 0
      });

      if (button.id !== 'insertButtonTwo') {
        for (let i = 0; i < this.lancamentos.controls.length; i++) {
          // this.removeItem(i, this.lancamentos.controls[i]);
          button.disabled = false;
        }
        this.resetForm();
        this.enviado = false;
        button.disabled = false;
        this.checkDocumentIsCarried();
      } else {
        button.disabled = false;
        const formValue2 = this.lancamentoForm.value
        if (formValue2.pessoa != null) {
          if (formValue2.documento !== '' && formValue2.pessoa.id !== undefined) {
            this.checkDocumentIsCarried();
          }
        }
      }

      if (this.conciliacao && !this.editar) {
        this.conciliate(result);
      }
    }).catch((result: HttpErrorResponse) => {
      button.disabled = false;
      this.snackBar.openLong('Não foi possível inserir! ' +
        this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping', this.setObjectToPost()), 'erro');
    });
  }

  async newOrEditedDocument(button) {
    button.disabled = true;
    this.enviado = true;

    if (this.lancamentoForm.valid) {
      if (this.isLocked()) {
        Swal.fire({
          title: 'Atenção!',
          text: 'Há lançamentos cadastrados dentro de um bloqueio de período. ' +
            (this.checkPermission.isAccountantAdmin() ?
              'Remova o bloqueio para poder cadastrar um lançamento nesta data.' :
              'Contate um administrador para que o bloqueio seja removido.'),
          icon: 'error'
        }).then((result) => {
          if (result.isConfirmed) {
            button.disabled = false;
          }
        });
      } else {
        if (!this.editar) {
          //console.log("novo documento");
          // novo documento
          this.checkWorkDate(this.lancamentoForm.getRawValue().lancamentos, button);
        } else {
          // edição de documento
          this.errosPut = [];
          this.putDocument(button);  // modifica dados do documento
          button.disabled = false;
        }
      }
    } else {
      if (!this.lancamentoForm.valid) {
        this.snackbar.openLong('Há erros no formulário! Corrija antes de enviar novamente.', 'erro');
        button.disabled = false;
      }
    }
  }

  changeEdit() {
    // this.planPaymentId = null;

    this.isChanging = true;
    this.isCloned = true;
    Swal.fire({
      title: 'Sucesso!',
      text: 'Esta escrituração foi replicada com sucesso.',
      icon: 'success',
      confirmButtonText: 'Ir para o formulário'
    }).then(() => {
      this.plannedPaymentId = null;
      for (let l of this.lancamentos.controls) {
        l.get('planPaymentId').setValue(null);
        l.value.id = '';
      }
      this.editar = false;
      this.checkDocumentIsCarried();
      this.isChanging = false;
    });

    // this.editar = false;
    // this.snackBar.open('Modelo criado com sucesso!', 'success');
    // window.scrollTo(0, 0);

  }

  setDefaultTipoLancamento(i: number) {
    if (this.lancamentos.controls[i].get('categoria').value.releaseType === 'NONE') {
      this.lancamentos.controls[i].get('tipoLancamento').setValue('0');
    } else {
      if (this.lancamentos.controls[i].get('categoria').value.featureType === 'REVENUE') {
        if (this.livroCaixa.getTipo() == 1 && this.lancamentos.controls[i].get('categoria').value.releaseType != "REVENUE_RURAL_ACTIVITY") {
          this.lancamentos.controls[i].get('tipoLancamento').setValue('3');
        } else {
          this.lancamentos.controls[i].get('tipoLancamento').setValue('1');
        }
      } else {
        this.lancamentos.controls[i].get('tipoLancamento').setValue('2');
      }
    }
  }

  deleteDocument() {
    const dialogRef = this.dialog.open(DeletarDocumentoComponent, {
      width: '350px',
      data: this.lancamentoExistenteID
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancelou' && result !== undefined) {
        this.changeMade = true;
        this.activeModal.close(this.changeMade);
      }
    });
  }

  checkIfHaveLancamentosOutOfWorkDate(lancamentos, startMonth, endMonth): boolean {
    let haveDateOut = false;
    lancamentos.forEach((lancamento: any) => {
      if (moment(moment(lancamento.dataPagamento).format('YYYY-MM-DD')).isBetween((startMonth), (endMonth))) {
      } else {
        haveDateOut = true;
      }
    });
    return haveDateOut;
  }

  openDialogWorkDate(button) {
    const dialogRef = this.dialog.open(DataTrabalhoDialog, { disableClose: true });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === undefined || result !== 'cancelou') {
        if(this.importedLancamentos != null){
          this.postImportedDocument(button);
        } else {
          this.postNewDocument(button);
        }
      } else {
        button.disabled = false;
      }
    });
  }

  checkWorkDate(lancamentos: [], button) {
    const workDate = localStorage.getItem('workDate');
    const startMonth = moment.tz(workDate, 'America/Sao_Paulo').endOf('month').subtract(1, 'month');
    const endMonth = moment.tz(workDate, 'America/Sao_Paulo').endOf('month');
    if (this.checkIfHaveLancamentosOutOfWorkDate(lancamentos, startMonth, endMonth)) {
      this.openDialogWorkDate(button);
    } else {
      if(this.importedLancamentos != null){
       //("posting import no check workdate")
        this.postImportedDocument(button);
      } else {
        this.postNewDocument(button);
      }
    }
  }

  generateHistoricModelsNewHistoric(index,model?): HistoricDTOResponse[] {
    //console.log("aaa23",model)
    let modelsFormated = [];
    let historicSplit = model
      let historicString = '';
      historicSplit.forEach(str => {
        if (str.includes('@')) {
          historicString = historicString + ' ' + this.getHistoricTag(str.trim(), index);
        } else {
          historicString = historicString + ' ' + str.trim();
        }
      });
      //console.log(historicString)
      model = historicString.trim();
      modelsFormated.push(model);

    return modelsFormated;
  }
  generateHistoricModels(index): HistoricDTOResponse[] {
    const modelStorage = JSON.parse(localStorage.getItem('historics'));
    const modelsFormated = [];
    modelStorage.forEach((historic: HistoricDTOResponse) => {
      const historicSplit = historic.model.split('#');
      let historicString = '';
      historicSplit.forEach(str => {
          historicString = historicString + ' ' + str.trim();
      });
      historic.model = historicString.trim();
      modelsFormated.push(historic);
    });
    return modelsFormated;
  }

  getHistoricTag(tag, index): string {
    const formValue = this.lancamentoForm.value;
    //console.log(tag, 'shitt')
    switch (tag) {
      case '@participante@':
        return this.checkIfValueExist(formValue.pessoa, 'name').trim().toUpperCase();
      case '@participante-CpfCnpj@':
        return formValue.pessoa.cpfCNPJParticipant || 'Cpf/Cnpj Não informado'
      case '@numero-documento@':
        return formValue.documento;
      case '@conta@':
        return this.checkIfValueExist(formValue.lancamentos[index].categoria, 'description').trim();
      case '@mes@':
        return moment(formValue.lancamentos[index].dataPagamento).locale('pt-br').format('MMM').toUpperCase();
      case '@ano@':
        return moment(formValue.lancamentos[index].dataPagamento).format('Y');
      case '@ano-anterior@':
        return moment(formValue.lancamentos[index].dataPagamento).subtract(1, 'year').format('Y');
      case '@mes-anterior@':
        return moment(formValue.lancamentos[index].dataPagamento).subtract(1, 'month').locale('pt-br').format('MMM').toUpperCase();
      case '@mes-e-ano-anterior@':
        return moment(formValue.lancamentos[index].dataPagamento).subtract(1, 'month').locale('pt-br').format('MMM Y').toUpperCase();
      default:
        return tag;
    }
  }

  checkIfValueExist(value, variable) {
    if (value === undefined || value === null) {
      return '';
    } else {
      switch (variable) {
        case 'name':
          if (value.name !== undefined) {
            return value.name.split(' -')[0];
          } else {
            return '';
          }
        case 'description':
          if (value.description !== undefined) {
            return this.getAccountPlanDescription(value);
          } else {
            return '';
          }
      }
    }
  }

  getAccountPlanDescription(accountPlan: AccountPlanDTOResponse): string {
    return accountPlan.description.replace(accountPlan.customId + ' - ', '');
  }

  resetForm() {
    const total = this.lancamentos.controls.length;
    const controls = this.lancamentoForm.controls.lancamentos as FormArray;
    controls.clear();
    this.lancamentoForm.reset();
    this.lancamentoForm.controls.imovel.setValue(this.imoveis.find(obj => obj.default));
    this.addNewLancamento();
    this.numeroDeParcelas = 1;
    this.groupers.splice(0, this.groupers.length);
    this.receipts.splice(0, this.receipts.length);
  }

  getFile(receipt) {
    this.carregando = true;
    //console.log("receipt.file",   receipt.file);
    this.receiptProvider.downloadFile(receipt.file).then((value: any) => {
      const extension = receipt.file.split('.').pop();
      let blob;
      if(extension == 'pdf' || extension == 'xls' || extension == 'xlsx' || extension == 'doc' || extension == 'docx' || extension == 'txt'){
        let type = 'application/' + extension;
        blob = new Blob([value], {type: type});
      }
      else if(extension == 'png' || extension == 'jpg' || extension == 'jpeg'){
        let type = 'image/' + extension;
        blob = new Blob([value], {type: type});
      }
      else{
        blob = new Blob([value], {type: 'application/octet-stream'});
      }
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      this.carregando = false;
    }).catch((error) => {
      this.snackBar.openLong('Erro ao baixar arquivo', 'error');
    });
  }

  protected filterProperties() {
    if (!this.imoveis) {
      return;
    }
    // get the search keyword
    let search = this.propertyFilterCtrl.value;
    if (!search) {
      this.filter_property.next(this.imoveis.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filter_property.next(
      this.imoveis.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterParticipants() {
    this.filter_participant.next(this.participantes.slice());
  }

  private _filterHistoric(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allHistorics.filter(historic => historic.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterG(value: any): any[] {
    if (typeof value === 'string') {
      const name = value;
      return value ? this.allGroupers.filter(fruit => fruit.name.toLowerCase().includes(name.toLowerCase())) : this.allGroupers;
    }
  }

  private _filterR(value: any): any[] {
    if (typeof value === 'string') {
      const name = value;
      return value ? this.allReceipts.filter(fruit => fruit.description.toLowerCase().includes(name.toLowerCase())) : this.allReceipts;
    }
  }

  getFileDfe(location) {
    this.loading = true;
    const id = this.plannedPaymentId;
    this.dfeProvider.getDfeByPlannedPayment(`${id}`).then((dfe: any) => {
      const document = {
        xml: atob(dfe.dfeBytes),
        modelo: dfe.modelType,
        chave: dfe.key
      };
      switch (location) {
        case 'modal':
          this.consultaPdfXml.getPdfModal(document).then((result) => {
            this.loading = false;
          }).catch((result: HttpErrorResponse) => {
            this.loading = false;
            console.error(result);
            this.snackbar.openLong('Não foi possível consultar o PDF/XML. ' +
            this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
          });
          break;
        case 'blank':
          this.consultaPdfXml.getPdf(document).then((result) => {
            this.loading = false;
          }).catch((result: HttpErrorResponse) => {
            this.loading = false;
            console.error(result);
            this.snackbar.openLong('Não foi possível consultar o PDF/XML. ' +
            this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
          });
          break;
      }
    }).catch((result: HttpErrorResponse) => {
      this.loading = false;
      console.error(result);
      this.snackbar.openLong('Não foi possível consultar o PDF/XML. ' +
      this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
    });
  }

  getFirstPage() {
    let pipe = new ConvertObjectPipe();
    this.participantPage = 0;
    this.participantFilterCtrl.setValue('');
    this.participantProvider.getParticipantsByPage(this.participantFilterCtrl.value, this.participantPage).then((participantes: ParticipantPageable) => {
      this.participantes = pipe.transform(participantes.content, 'participantes');
      this.participantPage++;

      this.currentParticipant = this.lancamentoForm.value.pessoa;

      if (this.currentParticipant == undefined || this.currentParticipant == null) {
        this.firstFromForm = false;
      } else {
        this.firstFromForm = true;
        this.participantes.unshift(this.currentParticipant);
      }

      this.filterParticipants();
    });
  }

  getDateLocks() {
    this.dateLockProvider.getDateLocks().then((value: DateLock[]) => {
      this.locks = value;
    });
  }

  isLocked() {

    for (let lancamento of this.lancamentoForm.value.lancamentos) {
      for (let lock of this.locks) {
        if (moment(lancamento.dataPagamento).isBetween(moment(lock.startDate), moment(lock.endDate), undefined, '[]') && lock.lock) {
          return true;
        }
      }
    }

    return false;
  }

  isLockedAt(lancamento) {
    for (let lock of this.locks) {
      if (moment(lancamento.dataPagamento).isBetween(moment(lock.startDate), moment(lock.endDate), undefined, '[]') && lock.lock) {
        return true;
      }
    }

    return false;
  }

  getNextBatch(isScroll: boolean) {
    let search = '';
    if (this.participantFilterCtrl.value != undefined) {
      search = this.participantFilterCtrl.value;
    }

    let pipe = new ConvertObjectPipe();
    if (isScroll) {
      this.participantProvider.getParticipantsByPage(search, this.participantPage).then((participantes: ParticipantPageable) => {
        this.participantLoaded = participantes.last;
        this.participantPage++;

        this.participantes = this.participantes.concat(
          pipe.transform(participantes.content, 'participantes')
        );
        this.filterParticipants();
      });
    } else {
      if (search.length >= 3 || search.length == 0) {
        this.participantPage = 0;

        this.participantProvider.getParticipantsByPage(search, this.participantPage).then((participantes: ParticipantPageable) => {
          this.participantLoaded = participantes.last;
          this.participantPage++;

          //console.log(participantes);

          this.participantes = pipe.transform(participantes.content, 'participantes');
          if (this.editar && search.length == 0) {
            this.participantes.unshift(this.currentParticipant);
            this.firstFromForm = true;
          } else {
            this.firstFromForm = false;
          }
          this.filterParticipants();
        });
      }
    }
  }

  editParticipant(participant){
    participant.name = participant.name.split(' -')[0];
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroPessoasComponent, modalOptions);
    modalRef.componentInstance.participantes = this.participantes;
    modalRef.componentInstance.participanteExistente = participant;
    modalRef.result.then((result: any) => {
      if (result){
        this.lancamentoForm.controls.pessoa.setValue(null);
        this.getFirstPage();
      }
    }, () => {
    });
  }

  editProperty(row: any) {
    this.carregando = true;
    this.propertyProvider.getAllProperty().then((imoveis: any[]) => {
      let property = imoveis.find((imovel) => imovel.propertyCode == row.propertyCode);
      this.carregando = false;
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
      };
      const modalRef = this.modalService.open(CadastroImovelComponent, modalOptions);
      modalRef.componentInstance.imovelExistente = property;
      modalRef.result.then((result) => {
        if (result) {
          this.carregando = true;
          this.propertyProvider.getAllProperty().then((imoveis: any[]) => {
            let pipe = new ConvertObjectPipe();
            this.imoveis = pipe.transform(imoveis, 'imoveis');
            this.initializeSearchSelect();
            this.carregando = false;
          }).catch((result: HttpErrorResponse) => {
            this.carregando = false;
          });
        }
      }, () => {
      });
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
    });
  }

  editContaMovimento(contaMovimento) {
    contaMovimento.description = contaMovimento.description.split(' - ')[1];
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroContaBancariaComponent, modalOptions);
    modalRef.componentInstance.contaExistente = contaMovimento;
    modalRef.result.then((result) => {
      if (result) {
        this.carregando = true;
        this.bankAccountProvider.getAllBanksAccounts().then((contas: any[]) => {
          let pipe = new ConvertObjectPipe();
          this.contas = pipe.transform(contas, 'contasBancarias');
          this.initializeSearchSelect();
          this.carregando = false;
        });
      }
    }, () => {
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
    });
  }

  editContaResultado(contaResultado){
  let modalOptions: NgbModalOptions = {
    backdrop: 'static',
    windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
  };
  const modalRef = this.modalService.open(CadastroPlanoDeContasComponent, modalOptions);
  // console.log("previous node", node)
  contaResultado.name = contaResultado.description.split(' - ')[1]
  modalRef.componentInstance.node = contaResultado;
  modalRef.componentInstance.editar = true;
  modalRef.componentInstance.root = false;
  modalRef.componentInstance.customIds = [contaResultado.customId];
  modalRef.componentInstance.descriptions = [contaResultado.description.split(' -')[1]];
  modalRef.componentInstance.escrituracao = true;
  modalRef.result.then((result) => {
    if (result) {
      this.carregando = true;
      this.accountProvider.getAllAccountsLeafs().then((categorias: AccountPlanDTOResponse[]) => {
        let pipe = new ConvertObjectPipe();
        this.categorias = pipe.transform(categorias, 'categoria');
        //refresh filter_planAccount[i] with the new categorias values
        let controls = this.lancamentos.controls;
        this.initializeFormArrayFilter(controls.length - 1);
        this.carregando = false;
      });
    }
  }, () => {
  }).catch((result: HttpErrorResponse) => {
    this.carregando = false;
  });
  }

  changeValues() {
    this.lancamentos.controls.forEach((control: FormGroup) => {
      control.controls.valor.setValue(this.dados.value);
      control.controls.descricao.setValue(this.dados.description);
      control.controls.contabancaria.setValue(this.contas.find(lancamento => lancamento.id === this.dados.contabancaria.id));
      control.controls.dataPagamento.setValue(this.dados.date);
    });
    //console.log(this.contas.filter(lancamento => lancamento.id === this.dados.contabancaria.id));

    // this.selected = this.contas.filter(lancamento => lancamento.id === this.dados.contabancaria.id)[0];
    // this.selectedText = this.selected.description;
  }

  conciliate(result) {
    const arrayIds = [];
    result.forEach(element => {
      arrayIds.push(element.id);
    });

    this.ofxProvider.conciliateTransactions(arrayIds, this.dados.id).then((value: any) => {
      if (value.status === "error") {
        Swal.fire({
          title: 'Erro!',
          html: value.message,
          icon: 'error'
        });
      } else if (value.status === "ok") {
        Swal.fire({
          title: 'Conciliação realizada com sucesso',
          icon: 'success'
        }).then(() => {
          this.activeModal.close(true);
        });
      }
    }).catch((result) => {
      Swal.fire({
        title: 'Erro!',
        html: result.message,
        icon: 'error'
      });
    });
  }

  changeSize(event, size){
    event.target.style.height = size + 'px';
  }
}

class PaymentEdit {
  id: number;
  companyId: number;
  amountPaid: string;
  duplicateId: number;
  historic: string;
  account: any;
  grouper: GrouperDTOResponse[];
  paymentDate: string;
  postingReleaseType: string;
  bankAccount: ContaBancariaDTO;
  doc: DocEdit;
  payment: Payment;
  modelo: string;
  historicoUtil;
  digital: boolean;
  planPaymentId: number;
  ofxId: number;
}

class Payment {
  id: number;
  companyId: number;
  proofDoc: any;
  docs: Doc[];
}

class ImportedPayment {
  doc : ImportedDoc;
  grouperIds: any[];
  payrollImportType: String;
}

abstract class DocAbstract {
  id: number;
  companyId: number;
  number: number;
  docType: string;
}

class DocEdit extends DocAbstract {
  property: ImovelDTO;
  participant: ParticipantDTOResponse;
}

class Doc extends DocAbstract {
  propertyCode: string;
  participantId: string;
  postings: Posting[];
  participant: PessoaFiscalDTO;
  receiptFileIds: any[];
}

class ImportedDoc {
  number: String;
  docType: String;
  propertyCode: String;
  participantId: String;
  receiptFileIds: any[];
  postings: ImportedPosting[];
}

class ImportedPosting {
  amountPaid: String;
  historic: String;
  paymentDate: String;
  accountPlanId: number;
  postingReleaseType: String;
  bankAccountId: number;
}

class Posting {
  id: number;
  companyId: number;
  amountPaid: string;
  duplicateId: number;
  historic: string;
  accountsChartId: number;
  paymentDate: string;
  planPaymentId: number;
  postingReleaseType: string;
  groupersIds: number[];
  bankAccountId: number;
  isDigital: boolean;
  docId: number;
  paymentId: number;
  ofxId: number;
}

class ConsultDate {
  endDate: any;
  startDate: any;
}
