import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SharedModule} from "../../shared/shared.module";
import {MatNativeDateModule, MatOptionModule} from "@angular/material/core";
import {Ng2TableModule} from "ng2-table";

import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSortModule} from "@angular/material/sort";
import {MatTreeModule} from "@angular/material/tree";
import {MatButtonModule} from "@angular/material/button";
import {TreetableModule} from "ng-material-treetable";
import {ConsolidacaoMensalComponent} from "./consolidacao-mensal/consolidacao-mensal.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {TreeGridAllModule} from "@syncfusion/ej2-angular-treegrid";
import {TreeTableConsolidacao} from "./consolidacao-mensal/treeTableConsolidacao/treeTableConsolidacao";
import {NgxCurrencyModule} from "ngx-currency";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatMenuModule} from "@angular/material/menu";
import { LivroRazaoComponent } from './livro-razao/livro-razao.component';
import {GridAllModule} from "@syncfusion/ej2-angular-grids";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import { LivroCaixaComponent } from './livro-caixa/livro-caixa.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTooltipModule} from "@angular/material/tooltip";
import { LivroCaixaAnalogicoComponent } from '../livro-caixa/livro-caixa-analogico/livro-caixa-analogico.component';
import {MatStepperModule} from "@angular/material/stepper";
import {DatePickerModule, DateRangePickerModule} from "@syncfusion/ej2-angular-calendars";
import {MatDividerModule} from "@angular/material/divider";
import {MatExpansionModule} from "@angular/material/expansion";
import {GroupByPipeMonth} from "../../../util/pipes/GroupByMonth";
import { AnaliseParticipanteComponent } from './analise-participante/analise-participante.component';
import { RelatorioEstoqueComponent } from './relatorio-estoque/relatorio-estoque.component';
import {DashBoardCharts} from "./consolidacao-mensal/charts";
import {MatTabsModule} from "@angular/material/tabs";


const routes: Routes = [
  {path: '', redirectTo: 'consolidacao-mensal', pathMatch: 'full'},
  {path: 'consolidacao-mensal', component: ConsolidacaoMensalComponent},
  {path: 'livro-razao', component: LivroRazaoComponent},
  {path: 'livro-caixa', component: LivroCaixaComponent},
  {path: 'analise-participante', component: AnaliseParticipanteComponent},
  {path: 'relatorio-estoque', component: RelatorioEstoqueComponent}
];

@NgModule({
  imports: [
    MatNativeDateModule,
    RouterModule.forChild(routes),
    Ng2TableModule,
    NgxDatatableModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSortModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    TreetableModule,
    SharedModule,
    MatDatepickerModule,
    TreeGridAllModule,
    NgxCurrencyModule,
    MatProgressBarModule,
    MatMenuModule,
    GridAllModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    NgxMatSelectSearchModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatStepperModule,
    DateRangePickerModule,
    MatDividerModule,
    MatExpansionModule,
    DatePickerModule,
    MatTabsModule
  ],
  declarations: [
    TreeTableConsolidacao,
    ConsolidacaoMensalComponent,
    RelatorioEstoqueComponent,
    LivroRazaoComponent,
    LivroCaixaComponent,
    GroupByPipeMonth,
    DashBoardCharts,
    AnaliseParticipanteComponent,
  ],
  exports: [
    RouterModule,
    MatSelectModule
  ]
})
export class RelatoriosModule {
}


